import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import inputfield from '../assets/inputfield.png'
import submitbtn from '../assets/submitbtn.png'
import otpborder from '../assets/otpborder.png'

export default function Otp(props) {

    let host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const navigate = useNavigate();
    const [credential, setCredential] = useState({ otp: '' });
    const [loader, setLoader] = useState(true);
    const [errorMassage, setErrorMassage] = useState('');


    const setupOtp = async () => {

        if (credential.otp === '') {
            return document.getElementById('viewalert').innerText = "OTP field can not be blank.";
        }

        setLoader(false);

        let myotp = credential.otp * 1
        const response = await fetch(`${host}/evotp/checkotp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userotp: myotp, email: localStorage.getItem('email') })
        });
        const json = await response.json();

        if (json === 'failed') {
            setLoader(true);
            setErrorMassage('inCorrect opt')
        } else {
            localStorage.clear();

            localStorage.setItem('token', json.authToken)
            setLoader(true);
            navigate('/');
            props.myMessage('Welcome aboard! Exciting adventures await.')
        }
    }


    const onChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value })
    }
    return (
        <>
            <section>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div>

                        {loader ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <img src={otpborder} width={800} alt="" />
                                </div>



                                <div className='form_create_user' style={{ position: 'absolute' }}>
                                    <div style={{ color: 'white', textAlign: 'center',fontSize:'23px' }}>
                                        <h2>Confirm your email…</h2>
                                    </div>
                                    <div className='l_input_col'>
                                        <img src={inputfield} width={500} alt="" />
                                        <input id="otp" value={credential.otp} onChange={onChange} name="otp" className='input_field' type="text" placeholder='Email OTP' />
                                    </div>

                                    <div>
                                        <p id='viewalert' style={{ color: 'red' }}>{errorMassage}</p>
                                    </div>

                                    <div className='l_submit_set' onClick={setupOtp}>
                                        <img src={submitbtn} width={170} alt="" />
                                        <p style={{ position: 'absolute' }}>Ready to Play</p>
                                    </div>



                                    <div style={{ color: 'white' }}>
                                        2:00
                                    </div>
                                </div>  </div>
                            : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                                <div className='spinner_data'> </div>
                            </div>}
                    </div>
                </div>



            </section>


        </>
    )
}
