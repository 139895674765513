import React, { useState, useEffect } from 'react'
import expcont from "../../assets/Summery 1.png"
import expectationback from "../../assets/pdfbackg.png"
import budgutamountback from "../../assets/sp_budget_back_amount.png"
import expsub from "../../assets/apply button black 2.png"
import expectations from "../../assets/sp_expection_back.png"
import { useNavigate, Link } from 'react-router-dom';
import Header from '../Header'
import Footer from '../Footer'
import person from "../../assets/person_logo_gamer.png"
import Nav_gamer from './Nav_gamer'
import blackbtn from "../../assets/blackbtn.png"

export default function GamerAchievement() {


  const [credential, setCredential] = useState({ contant: '', id: '' });
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState('');
  const [exp, setExp] = useState([]);
  const host = process.env.REACT_APP_API_REQUEST;
  const [loader, setLoader] = useState(false);
  // const host = "http://localhost:5000";
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [picurl, setPicurl] = useState();


  const addExpection = () => {
    document.getElementById('sp_add_expec').classList.toggle("disnone");
  }
  const addExpectionpdf = () => {
    document.getElementById('sp_add_expdf').classList.toggle("disnone");
  }
  const addPdf = () => {
    document.getElementById('sp_add_expec').classList.toggle("disnone");
    document.getElementById('sp_add_expdf').classList.toggle("disnone");
  }
  const editPdfContant = (eId, summary) => {
    document.getElementById('sp_add_expdfupdate').classList.toggle("disnone");
    setCredential({ contant: summary, id: eId });
  }
  const editPdfContantUpdate = async () => {
    document.getElementById('sp_add_expdfupdate').classList.toggle("disnone");
    setLoader(false)
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "gamer_adda");
    data.append("cloud_name", "dw5zqoz0n");
    try {
      if (image === null) {
        return console.log('work..');
      }

      const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
        method: "POST",
        body: data
      })


      const cloudData = await res.json();
      setUrl("v" + cloudData.version + "/" + cloudData.public_id);

      const response = await fetch(`${host}/ach/expupdate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pdfUrl: "v" + cloudData.version + "/" + cloudData.public_id, summary: credential.contant, id: credential.id })
      });
      const json = await response.json();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }
  const onChange = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value })
  }
  const fetchExpectation = async () => {
    const uresponse = await fetch(`${host}/auth/getuser`, {
      method: 'GET',
      headers: {
        'auth-token': localStorage.getItem('token')
      }
    });
    const userid = await uresponse.json();
    console.log(userid);
    if (userid) {
      const response = await fetch(`${host}/ach/getexp`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json = await response.json();
      setExp(json);
      setLoader(true)
      console.log(json);
    }
  }

  const addPdfData = async () => {
    document.getElementById('sp_add_expdf').classList.toggle("disnone");
    setLoader(false)
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "gamer_adda");
    data.append("cloud_name", "dw5zqoz0n");

    try {
      if (image === null) {
        return console.log('work..');
      }

      const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
        method: "POST",
        body: data
      })

      const cloudData = await res.json();
      setUrl("v" + cloudData.version + "/" + cloudData.public_id);

      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();
      if (userid) {
        const response = await fetch(`${host}/ach/createexp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ pdfUrl: "v" + cloudData.version + "/" + cloudData.public_id, summary: credential.contant, userId: userid._id })
        });
        const json = await response.json();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }


  const deleteAchi = async () => {

    const response = await fetch(`${host}/ach/deleteach`, {
      method: 'POST',
      headers: {
        'id': credential.id
      },

    });
    const json = await response.json();
    console.log(credential.id);
    window.location.reload();

  }

  const handleExpectAmount = (id, amount) => {
    document.getElementById('sp_add_achi_delete').classList.toggle("disnone");
    console.log(id);
    setCredential({ amount: amount, id: id })
  }

  const updateAmounttoggle = () => {
    document.getElementById('sp_add_expect_delete').classList.toggle("disnone");

  }


  const fetchImage = async () => {
    const uresponse = await fetch(`${host}/auth/getuser`, {
      method: 'GET',
      headers: {
        'auth-token': localStorage.getItem('token')
      }
    });
    const userid = await uresponse.json();
    setUser(userid);
    if (userid) {
      const response = await fetch(`${host}/pic/getpic`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json = await response.json();
      if (json.length === 1) {
        setPicurl(json[0].picurl)
      }
    }
  }




  useEffect(() => {
    fetchImage();
    fetchExpectation();
  }, [])



  return (
    <>
      <Header />
      <div>
        <br />
        <br />
        <br />
        <br />
      </div>


      <div>
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <Nav_gamer />
          {/* <div>
            {picurl?  <img width={200} style={{borderRadius:'50%'}} src={picurl} alt="" />: <img width={200} src={person} alt="" />}
          </div>
          <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
            {user? user.username  : "-" }
          </div> */}
          <div style={{ marginTop: '15px' }}>
            <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
          </div>
        </div>

        <div className='profile_section'>
          <div className='profile_sec'>
            <Link to="/editgamer/bio" style={{ textDecoration: 'none' }}>
              <div className=" profile_header"  >
                Bio
              </div>
            </Link>
            <div className=" profile_nav_white profile_header" >
              ACHIEVEMENTS
            </div>
            <Link to="/editgamer/events" style={{ textDecoration: 'none' }}>
              <div className="profile_header" >
                EVENTS
              </div>
            </Link>
            {/* <Link to="/editgamer/profile" style={{ textDecoration: 'none' }}>
              <div className="profile_header" >
                EDIT DETAILS
              </div>
            </Link> */}
          </div>
        </div>
      </div>


      {loader ?
        <div className='sp_budget_id'>
          <p style={{ color: 'white', textAlign: 'center', width: '1000px', fontSize: '20px', textTransform: 'uppercase' }}>
            Add PDF Document
          </p>
          <div onClick={addExpection}>
            <div className='sp_budget_add'>
              +
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>

            {exp.map((element, myid) => {





              return <div key={myid}>
                <div style={{ width: '400px', margin: '50px' }} >
                  <a className='pdf_link_set' style={{ cursor: "context-menu" }}>
                    <div className='expect_card_pdf'>
                      <span class="material-symbols-outlined" onClick={() => editPdfContant(element._id, element.summary)}>edit</span>
                      <span class="material-symbols-outlined" onClick={() => handleExpectAmount(element._id, element.summary)}>delete</span>
                    </div>
                    <img src={`http://res.cloudinary.com/dw5zqoz0n/image/upload/${element.pdfUrl}.png`} width="400px" height="300px" alt="" />
                    <a style={{ textDecoration: 'none' }} href={`http://res.cloudinary.com/dw5zqoz0n/image/upload/${element.pdfUrl}.pdf`} target='_blank'>
                      <div className='pdf_para-set'>
                        <p>{element.summary}</p>
                      </div>
                    </a>
                  </a>
                </div>
              </div>




            })}

          </div>

        </div>
        : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
          <div className='spinner_data'></div>
        </div>
      }








      <section id='sp_add_expec' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img width={700} src={expectations} alt="" />
          <div className='sp_add_img_here' style={{ color: "black", marginTop: '-344' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '52%' }}>

              <div style={{ opacity: '0' }}><h3>Notification</h3></div>
              <div style={{ marginTop: '6px', cursor: 'pointer', position: 'fixed', top: '30%', right: '30%', color: 'white' }} onClick={addExpection}><span class="material-symbols-outlined">
                close
              </span></div>
            </div>
            <div style={{ width: '372px', textTransform: 'uppercase', marginTop: '-68px', marginRight: '-40px', textAlign: 'center', fontSize: '21px', fontWeight: 'bolder' }}>
              <p>Add a PDF document with information about what you have achieved in the past i.e., any successful events/tournaments hosted.  (Maximum file size is 10MB)

              </p>
            </div>
            <div>
              <div onClick={addPdf} style={{ cursor: 'pointer' }}>
                <img width={150} src={blackbtn} alt="" />

                <p style={{ color: 'white', marginTop: '-40px', textAlign: 'center', cursor: 'pointer' }}>OK</p>
              </div>
              {/* <button onClick={addPdf}>Ok</button> */}
            </div>
          </div>
        </div>
      </section>

      <section id='sp_add_expdf' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img width={500} src={expectationback} alt="" />
          <div className='sp_add_img_here' style={{ color: "black", marginTop: '-390px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '52%' }}>

              <div><h3 style={{ opacity: '0' }}>Notification</h3></div>
              <div style={{ marginTop: '6px', cursor: 'pointer', position:'fixed',top:'20%',right:'30%' }} onClick={addExpectionpdf}><span class="material-symbols-outlined" style={{color:'white'}}>
                close
              </span></div>
            </div>
            <div>
            
              <input type="file" id='file' style={{marginBottom:'40px'}} onChange={(e) => setImage(e.target.files[0])} name="file" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ position: 'absolute' }} width={400} src={expcont} alt="" />
              <textarea className='exp_input_para' id="contant" style={{background:'transparent',color:'white'}}  value={credential.contant} type='text' onChange={onChange} name="contant" placeholder='Add a summary in 50 characters.' />
            </div>
            <div >
              <img width={200} src={expsub} alt="" />

              <p className='exp_para_submit' style={{color:'white',marginTop:'-53px'}} onClick={addPdfData}>Submit</p>
            </div>
          </div>
        </div>
      </section>

      <section id='sp_add_expdfupdate' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img width={500} src={expectationback} alt="" />
          <div className='sp_add_img_here' style={{ color: "black", marginTop: '-390px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '52%' }}>

              <div><h3 style={{ opacity: '0' }}>Notification</h3></div>
              <div style={{ marginTop: '6px', cursor: 'pointer', position:'fixed',top:'20%',right:'30%' }} onClick={editPdfContant}><span class="material-symbols-outlined" style={{color:'white'}}>
                close
              </span></div>
            </div>
            <div>
              {/* <p >ONLY PDF file</p> */}
              <input type="file" id='file' style={{marginBottom:'40px'}} onChange={(e) => setImage(e.target.files[0])} name="file" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ position: 'absolute' }} width={400} src={expcont} alt="" />
              <textarea className='exp_input_para' style={{background:'transparent',color:'white'}}  id="contant" value={credential.contant} type='text' onChange={onChange} name="contant" placeholder='Add a summary in 50 characters.' />
            </div>
            <div >
              <img width={200} src={expsub} alt="" />

              <p className='exp_para_submit' style={{color:'white',marginTop:'-53px'}} onClick={editPdfContantUpdate}>Submit</p>
            </div>
          </div>
        </div>
      </section>

      <section id='sp_add_achi_delete' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>

          <div className='close_bio_sp'><span onClick={updateAmounttoggle} class="material-symbols-outlined">close</span></div>
          <div className='card_delete_confirm'>
            <p>Are you sure want to delete that {credential.amount}</p>
            <div>
              <button className='confirm_delete_btn' onClick={deleteAchi}>Ok</button>
            </div>
          </div>
        </div>
      </section>

      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  )
}
