import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import bioback from "../../assets/sponsorbioback.png";
import sponsors from '../../assets/web logo Resize.png'
import budgutamountok from "../../assets/apply button black 2.png"
import '../css/Userbio.css'
import Footer from '../Footer';
import Header from '../Header';
import person from "../../assets/person_logo_gamer.png"
import Nav_sponsor from './Nav_sponsor';

export default function Userbio() {
    const navigate = useNavigate();
    const host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const [credential, setCredential] = useState({ userbio: '' });
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState();
    const [picurl, setPicurl] = useState();

    const editBio = () => {
        document.getElementById('sp_editbio').classList.toggle("disnone");
    }
    const submitBio = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        if (userid) {
            const response = await fetch(`${host}/userbio/createbio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userBio: credential.userbio, userId: userid._id })
            });
            const json = await response.json();
            window.location.reload();
        }
        document.getElementById('sp_editbio').classList.toggle("disnone");
    }
    const onChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value })
    }


    const fetchData = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        setUser(userid);
        if (userid) {
            const response = await fetch(`${host}/userbio/getbio`, {
                method: 'GET',
                headers: {
                    'userid': userid._id
                }
            });
            const json = await response.json();
            if (json.length === 1) {
                setCredential({ userbio: json[0].userBio });
            }
            setLoader(true);
        }
    }



    const fetchImage = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        if (userid) {
            const response = await fetch(`${host}/pic/getpic`, {
                method: 'GET',
                headers: {
                    'userid': userid._id
                }
            });
            const json = await response.json();
            console.log(json);
            if (json.length === 1) {
                // setCredential({ userbio:  });
                console.log(json[0].picurl);
                setPicurl(json[0].picurl)
            }
            // setLoader(true);
        }
    }

    useEffect(() => {
        fetchData();
        fetchImage();
    }, [])


    return (
        <>
            <Header />
            <div>
                <br />
                <br />
                <br />
                <br />
            </div>
            <div>
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                        <Nav_sponsor />
                    </div>
                    {/* <div>
                        {picurl?  <img width={200} style={{borderRadius:'50%'}} src={picurl} alt="" />: <img width={200} src={person} alt="" />}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                        {user? user.username  : "-" }
                    </div> */}


                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>


                        <div className="profile_nav_white profile_header"  >
                            Bio
                        </div>

                        <Link to="/editsponsor/budget" style={{ textDecoration: 'none' }}>
                            <div className="  profile_header" >
                                Budget
                            </div>
                        </Link>

                        <Link to="/editsponsor/expect" style={{ textDecoration: 'none' }}>
                            <div className="  profile_header" >
                                Expectations
                            </div>
                        </Link>

                        {/* <Link to="/editsponsor/profile" style={{ textDecoration: 'none' }}>
                            <div className="profile_header" >
                                Edit DETAILS
                            </div>
                        </Link> */}

                    </div>
                </div>

            </div>





            {loader ?
                <div className='sp_bio'>
                    <div className='sp_bio_inner'>

                        <img src={bioback} width={800} alt="" />
                        <div className='userbio_pen_logo' onClick={editBio}>
                            <span class="material-symbols-outlined" style={{ fontSize: '50px' }}>edit</span>
                        </div>
                        <p className='sp_bio_contant'>
                            {credential.userbio ? credential.userbio : 'No Bio'}
                        </p>
                    </div>
                </div>
                : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                    <div className='spinner_data'> </div>
                </div>}

            <section id='sp_editbio' className='sp_budget_add_id disnone'>
                <div className='close_bio_sp' style={{position:'fixed',top:'30%',right:'20%'}}><span onClick={editBio} style={{fontSize:'25px'}} class="material-symbols-outlined">close</span></div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={bioback} width={1000} alt="" />
                    <div className='sp_userbio_contant' style={{marginTop:'-395px'}}>
                        <textarea id="userbio" value={credential.userbio} onChange={onChange} name="userbio" type="text" />

                        <div className='sp_add_btn' style={{marginTop:'-10px'}}>
                            <img width={150} src={budgutamountok} alt="" />
                            <div onClick={submitBio}>submit</div>
                        </div>
                        {/* <button onClick={submitBio}>submit</button> */}
                    </div>
                </div>
            </section>


            <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>

            <Footer />
        </>
    )
}
