import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Massages from '../components/Massages'
import Char1 from '../assets/char1.png'
import Char2 from '../assets/char2.png'
import Char3 from '../assets/char3.png'
import social1 from '../assets/social_media/discord.png'
import social2 from '../assets/social_media/facebook.png'
import social3 from '../assets/social_media/twitter.png'
import social4 from '../assets/social_media/youtube.png'
import social5 from '../assets/social_media/linkdein.png'
import social6 from '../assets/social_media/instagram.png'
import member1 from '../assets/member/muthu.png'
import member2 from '../assets/member/aman.png'
import member3 from '../assets/member/hemant.png'
import member4 from '../assets/member/surya.png'



import homebackground from '../assets/homebackground.mp4'
// import enterpop from '../assets/video_effect/firstEffect.mp4'
import { useEffect, useState } from 'react';
import '../components/css/Home.css'

import Odometer from 'react-odometerjs'
import 'odometer/themes/odometer-theme-default.css';
import Notify from '../components/Notify'





export default function Home(props) {

    const [count, setcount] = useState(1);
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState(0);
    const [value3, setValue3] = useState(0);

    const [gamer, setGamer] = useState(0);
    const [sponsor, setSponsor] = useState(0);


    const host = process.env.REACT_APP_API_REQUEST;

    // const host = "http://localhost:5000";

    const navigateToCont = (e) => {

        setcount(e)
    }

    window.addEventListener("scroll", () => {

        if (window.pageYOffset > 1300) {
            setValue(sponsor);
            setValue2(gamer);
            setValue3(sponsor + gamer);
        }
    })

    const fetchSponsor = async () => {
        const uresponse = await fetch(`${host}/getuser/sponsor`, {
            method: 'GET',
        });
        const userdata = await uresponse.json();
        console.log(userdata.length);
        setSponsor(userdata.length)

        const uresponse2 = await fetch(`${host}/getuser/gamer`, {
            method: 'GET',
        });
        const userdata2 = await uresponse2.json();
        console.log(userdata2.length);
        setGamer(userdata2.length)
    }

    // const fetchGamer = async () => {
    //     const uresponse = await fetch(`${host}/getuser/gamer`, {
    //         method: 'GET',
    //     });
    //     const userdata = await uresponse.json();
    //     console.log(userdata.length);
    //     setGamer(userdata.length)
    // }


    useEffect(() => {
        fetchSponsor();
     
    }, [])



    return (
        <>
            <head>
                <link rel="stylesheet" href="odometer-theme-default.css" />
            </head>

            <Header myMessage={props.myMessage} setHeadProgress={props.setProgress} />

            {/* <Notify/> */}




            <section>
                <div>
                    <video id='myvideo' autoPlay loop muted className='home_video' style={{ width: '100%' }}>
                        <source src={homebackground} type="video/mp4" />
                    </video>
                </div>



                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>

                {/* <div>
                    <video autoPlay loop muted style={{ width: '100%' ,height:'100vh', objectFit:'fill' }}>
                        <source src={enterpop} type="video/mp4" />
                    </video>
                </div> */}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <img src={Char3} className='about_img_width' alt="" />
                    </div>
                    <div className='about_set_center_by_media'>
                        <div className='ab_sect_info' >

                            {count === 1 ? <>  <h3 style={{ fontSize: '40px', margin: '0' }}>About Us</h3>
                                <p className='about_contant_here' >
                                    Gamers Adda is a platform that connects Gamers/Gaming Organizations, and Companies. Gamers/organizations that want to host gaming events/tournaments can find companies that are willing to fund their events/tournaments. Similarly, sponsors can filter through profiles and find suitable gamers/organizations to represent their company. At Gamers Adda, we want to eliminate the struggle of Gamers having to run around and find sponsors for gaming events/tournaments they want to host regardless of the budget or size.

                                </p></> : <></>}
                            {count === 2 ? <>  <h3 style={{ fontSize: '40px', margin: '0' }}>Gamers</h3>
                                <p className='about_contant_here'>
                                    Gamers are one-half of Gamers Adda. We want Gamers to get together and share their joy for Gaming through Events and Tournaments that bring millions of gamers together. After all, Sharing is Caring. Gaming is one of the largest communities and bringing Gamers together is our goal. Gamers Adda will make organising Gaming Events and Tournaments seamless and effortless for all Gamers alike.
                                </p></> : <></>}
                            {count === 3 ? <>  <h3 style={{ fontSize: '40px', margin: '0' }}>Sponsors</h3>
                                <p className='about_contant_here'>
                                    Sponsors complete the other half at Gamers Adda. Sponsors will breathe life into the Gaming Events/Tournaments through funding. In return, Sponsors can demand certain marketing benefits that will increase their brand exposure. We make it easier for Sponsors to filter through Gamers and their Events that will best fit their budget, scale and exposure.
                                </p></> : <></>}

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
                                <div onClick={() => { navigateToCont(1) }} className={count === 1 ? 'about_line ab_sect_line' : 'about_line'} ></div>
                                <div onClick={() => { navigateToCont(2) }} className={count === 2 ? 'about_line ab_sect_line' : 'about_line'} ></div>
                                <div onClick={() => { navigateToCont(3) }} className={count === 3 ? 'about_line ab_sect_line' : 'about_line'} ></div>
                            </div>
                        </div>
                    </div>
                </div>



                <div style={{ color: "white" }}>
                    <br />
                    <br />
                    <br />

                </div>


                <section>



                    <div className='home_member_set_cont' loading="lazy">
                        <div style={{ textAlign: 'center' }}>

                            <p className='home_re_sp' >Sponsors</p>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <div className='home_number_set'>
                                    <Odometer format="d" duration={1000} value={value} />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <p className='home_re_sp'>Gamers</p>
                            <div style={{ display: "flex", justifyContent: 'center' }}>

                                <div className='home_number_set'>
                                    <Odometer format="d" duration={1000} value={value2} />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <p className='home_re_sp'>Total</p>
                            <div style={{ display: "flex", justifyContent: 'center' }}>

                                <div className='home_number_set'>
                                    <Odometer format="d" duration={1000} value={value3} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />


                    </div>



                    <div className='partner_hall'>
                        <div className='partner_hall_inner'>
                            <a href="https://www.linkedin.com/in/muthu-nk-81a8a2249/" target='_blank' style={{ textDecoration: 'none' }}>
                                <img className='member_img_set' src={member1} alt="" />
                                <div className='member_name_contant' >
                                    <b>@MUTHU</b>
                                    <div>FOUNDER AND CEO</div>
                                </div>
                            </a>
                        </div>
                        <div className='partner_hall_inner'>
                            <a href="https://www.linkedin.com/in/imaman-gupta/" target='_blank' style={{ textDecoration: 'none' }}>
                                <img className='member_img_set' src={member2} alt="" />
                                <div className='member_name_contant' >
                                    <b>@AMAN</b>
                                    <div>CTO</div>
                                </div>
                            </a>
                        </div>
                        <div className='partner_hall_inner'>
                            <a href="https://www.linkedin.com/in/hemanth-kumar-879a941b1/" target='_blank' style={{ textDecoration: 'none' }}>
                                <img className='member_img_set' src={member3} alt="" />
                                <div className='member_name_contant' >
                                    <b>@HEMANTH</b>
                                    <div>DESIGN AND UI HEAD</div>
                                </div>
                            </a>
                        </div>
                        <div className='partner_hall_inner'>
                            <a href="https://www.linkedin.com/in/suryan17/" target='_blank' style={{ textDecoration: 'none' }}>
                                <img className='member_img_set' src={member4} alt="" />
                                <div className='member_name_contant' >
                                    <b>@SURYA</b>
                                    <div className='surya_style'>CONTENT AND PARTNERSHIP HEAD</div>
                                </div>
                            </a>
                        </div>

                    </div>

                </section>


                <div>
                    <br />
                    <br />

                </div>




                <div className='footer_link_cortoon' >

                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'flex-end', width: '100%', padding: '0px 10px', marginBottom: '-6px' }}>
                        <div>
                            <img src={Char1} className='home_down_app' alt="" />
                        </div>
                        <div className='social_home_page'>
                            <a href="https://www.linkedin.com/in/gamers-adda-7099882a8/" target='_blank'>
                                <img src={social5} alt="" style={{ margin: '10', marginBottom: "29px", marginRight: "17px" }} /></a>


                            <a href="https://www.instagram.com/_gamersadda_/" target='_blank'>
                                <img src={social6} alt="" style={{ margin: '10' }} /></a>

                            <a href="https://twitter.com/_GamersAdda_" target='_blank'>
                                <img src={social3} alt="" style={{ margin: '10' }} /></a>

                            <a href="https://www.youtube.com/@GamersAdda....." target='_blank'>
                                <img src={social4} alt="" style={{ margin: '10' }} /></a>


                            <a href="https://discord.com/invite/s64tgc5e" target='_blank'>
                                <img src={social1} alt="" style={{ margin: '10', marginRight: '-20px' }} /></a>

                            <a href="https://www.facebook.com/profile.php?id=61555783628032" target='_blank'>
                                <img src={social2} alt="" style={{ margin: '10' }} /></a>


                        </div>
                        <div>
                            <img src={Char2} className='home_down_app' alt="" />
                        </div>
                    </div>
                </div>




            </section>
            <Footer />

        </>
    )
}
