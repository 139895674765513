import Esport from './pages/Esport';
import Explore from './pages/Explore';
import Home from './pages/Home';
import { Routes, Route } from "react-router-dom";
import More from './pages/More';
import About from './pages/About';
import LoadingBar from 'react-top-loading-bar'
import { useState } from 'react';
import Login from './pages/Login';
import Register from './pages/Register';
import Gamers from './pages/Gamers';
import Sponsors from './pages/Sponsors';
import Articles from './pages/Articles';
import Buy from './pages/Buy';
import GamerProfile from './pages/GamerProfile';
import SponsorProfile from './pages/SponsorProfile';
import EsportPlayer from './pages/EsportPlayer';
import logo from './assets/web logo Resize.png'
import CreateGamers from './pages/CreateGamers';
import Otp from './pages/Otp';
import Welcome from './pages/Welcome';
import CreateSponsor from './pages/CreateSponsor';
import Sotp from './pages/Sotp';
import EditSponsor from './pages/EditSponsor';
import EditGamer from './pages/EditGamer';
import Footer from './components/Footer';
import Userbio from './components/edit_sponsor/Userbio';
import Budget from './components/edit_sponsor/Budget';
import Expectation from './components/edit_sponsor/Expectation';
import EditProfile from './components/edit_sponsor/EditProfile';
import GamerProfileDetalis from './components/edit_gamer/EditProfile';
import GamerBio from './components/edit_gamer/GamerBio';
import GamerAchievement from './components/edit_gamer/GamerAchievement';
import GamerEvent from './components/edit_gamer/GamerEvent';
import SponsorMassage from './components/view_sponsor/SponsorMassage';
import SponsorExpectation from './components/view_sponsor/SponsorExpectation';
import SponsorBudget from './components/view_sponsor/SponsorBudget';
import SponsorBio from './components/view_sponsor/SponsorBio';

import ViewGamerBio from './components/view_gamer/GamerBio';

import ViewGamerEvent from './components/view_gamer/GamerEvent';
import ViewGamerMassage from './components/view_gamer/GamerMessage';
import ViewGamerAchi from './components/view_gamer/ViewGamerAchi';
// import Massages from './components/Massages';
import SmallDe from './components/SmallDe';
import Notify from './components/Notify';


function App() {

  const [progress, setProgress] = useState(0);
  const [alert, setAlert] = useState(null);

  const increasePro = (progress) => {
    setProgress(progress);
  }

  const showAlert = (massage) => {
   
    setAlert(massage)
    setTimeout(() => {
      setAlert(null);
    }, 7000);
  }



  return (
    <>
    
      {/* {window.innerWidth < 1200 ?  */}

        <>

          <LoadingBar
            color='#4b206d'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          {/* <Massages /> */}

          <Notify alert={alert}/>

          <Routes>

            <Route path='/' element={<>
              <Home myMessage={showAlert} setProgress={increasePro} />
            </>} />

            <Route path='/explore' element={<>
              <Explore  setProgress={increasePro} />
            </>} />
            <Route path='/esport' element={<>
              <Esport myMessage={showAlert} setProgress={increasePro} />
            </>} />
            <Route path='/about' element={<>
              <About setProgress={increasePro} />
            </>} />
            <Route path='/more' element={<>
              <More setProgress={increasePro} />
            </>} />

            <Route path='/esportplayer' element={<>
              <EsportPlayer setProgress={increasePro} />
            </>} />


            <Route path='/login' element={<>
              <Login myMessage={showAlert}/>
            </>} />

            <Route path='/register/:whois' element={<>
              <Register />
            </>} />

            <Route path='/creategamer' element={<>
              <CreateGamers />
            </>} />

            <Route path='/createsponsor' element={<>
              <CreateSponsor />
            </>} />
            <Route path='/otp' element={<>
              <Otp myMessage={showAlert} />
            </>} />
            <Route path='/sotp' element={<>
              <Sotp />
            </>} />
            <Route path='/welcome' element={<>
              <Welcome />
            </>} />




            <Route path='/gamers' element={<>
              <Gamers setProgress={increasePro} />
            </>} />
            <Route path='/sponsors' element={<>
              <Sponsors myMessage={showAlert} setProgress={increasePro} />
            </>} />
            <Route path='/articles' element={<>
              <Articles setProgress={increasePro} />
            </>} />
            <Route path='/buy' element={<>
              <Buy setProgress={increasePro} />
            </>} />






            <Route path='/gamerprofile/:gamerid' element={<>
              <GamerProfile setProgress={increasePro} />
            </>} />
            <Route path='/gamerprofile/bio/:gamerid' element={<>
              <ViewGamerBio />
            </>} />
            <Route path='/gamerprofile/achievement/:gamerid' element={<>
              <ViewGamerAchi />
            </>} />

            <Route path='/gamerprofile/event/:gamerid' element={<>
              <ViewGamerEvent />
            </>} />

            <Route path='/gamerprofile/message/:gamerid' element={<>
              <ViewGamerMassage />
            </>} />






            <Route path='/sponsorprofile/:sponsorid' element={<>
              <SponsorProfile setProgress={increasePro} />
            </>} />

            <Route path='/sponsorprofile/bio/:sponsorid' element={<>
              <SponsorBio />
            </>} />
            <Route path='/sponsorprofile/budget/:sponsorid' element={<>
              <SponsorBudget />
            </>} />
            <Route path='/sponsorprofile/expectation/:sponsorid' element={<>
              <SponsorExpectation />
            </>} />
            <Route path='/sponsorprofile/massage/:sponsorid' element={<>
              <SponsorMassage />
            </>} />









            <Route path='/editsponsor' element={<>
              <EditSponsor setProgress={increasePro} />
            </>} />

            <Route path='/editsponsor/bio' element={<>
              <Userbio />
            </>} />

            <Route path='/editsponsor/budget' element={<>
              <Budget />
            </>} />

            <Route path='/editsponsor/expect' element={<>
              <Expectation />
            </>} />

            <Route path='/editsponsor/profile' element={<>
              <EditProfile />
            </>} />






            <Route path='/editgamer' element={<>
              <EditGamer setProgress={increasePro} />
            </>} />

            <Route path='/editgamer/bio' element={<>
              <GamerBio />
            </>} />

            <Route path='/editgamer/achievements' element={<>
              <GamerAchievement />
            </>} />
            <Route path='/editgamer/events' element={<>
              <GamerEvent />
            </>} />

            <Route path='/editgamer/profile' element={<>
              <GamerProfileDetalis />
            </>} />




          </Routes>

        </>
    </>
  );
}

export default App;
