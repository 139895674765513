import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import comingsoon from "../assets/light coming soon .png"
import '../components/css/More.css'

export default function More(props) {

  const showAns = (obj)=>{
    document.getElementById(obj).classList.toggle("disnone");
    // document.getElementById('pop_submit_pic').classList.toggle("disnone");
    console.log('werk');
  }
  return (
    <>
      <Header setHeadProgress={props.setProgress} />
      <div>

        <br />
        <br />
        <br />
        <br />
        <br />
        {/* <div style={{ textAlign: 'center', marginTop: '11px' }}>

          <img src={comingsoon} width={500} alt="" />
        </div> */}

        <section className='More_sectionss' >
          <div>
            <h2>FAQs</h2>
          </div>


          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_1')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>1. How can gamers establish connections with sponsors?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_1' className='disnone'>
              <p  >Gamers can navigate to the Sponsors tab, choose a sponsor of their preference, and use the filter feature to streamline their selection.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_2')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>2. How can sponsors establish connections with gamers?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_2'  className='disnone'>
              <p  >Sponsors can visit the Gamers tab, choose a gamer they're interested in, and use the filter feature to refine their search among all available gamers.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_3')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>3. How do I reach out to you?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_3'  className='disnone'>
              <p  >Feel free to reach us at muthu@gamersadda.io or by giving us a call at (+91) - 9880692322.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_4')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>4. How can gamers enhance their outreach?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_4'  className='disnone'>
              <p  >Ensure your profile is up-to-date, featuring a compelling bio, sharing past achievements, and showcasing hosted events to provide sponsors with more insights for interaction.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_5')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>5. How can companies improve their outreach?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_5'  className='disnone'>
              <p  >Maintain an updated profile with a comprehensive bio, budget information, and clear expectations to enable gamers to learn more about your company and reach out.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_6')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>6. How does the Messaging feature operate?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_6'  className='disnone'>
              <p  >Within the profile section, users can find the Message feature to communicate with gamers or sponsors, initiating a chatbox where all conversations can be conveniently tracked.</p>
            </div>
          </div>


          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_7')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>7. What information should gamers include in their Bio?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_7'  className='disnone'>
              <p  >Gamers should fill their bio with relevant personal information.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_8')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>8. What information should gamers include in Achievements?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_8'  className='disnone'>
              <p  >Achievements can encompass anything notable in the gaming or organizational sphere, and gamers can upload these accomplishments as a PDF file.</p>
            </div>
          </div>

          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_9')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>9. What should gamers include in the Events section?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_9'  className='disnone'>
              <p  >The Events tab should feature a PDF file detailing past events organized by the gamers.</p>
            </div>
          </div>
          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_10')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>10. What information should Sponsors include in their Bio?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_10'  className='disnone'>
              <p  >Sponsors should complete their bio with pertinent company information.</p>
            </div>
          </div>
          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_11')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>11. What should sponsors include in the Budget section?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_11'  className='disnone'>
              <p  >Specify the amount the sponsor is willing to provide for collaboration.</p>
            </div>
          </div>
          <div className='more_faq_cont'>
            <div onClick={() => showAns('more_12')} style={{cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <h3>12. What should sponsors include in Expectations?</h3>
              <span class="material-symbols-outlined">expand_more</span>
            </div>
            <div id='more_12'  className='disnone'>
              <p   >Provide details on expectations from gamers in a PDF file for clear communication.</p>
            </div>
          </div>


      




        </section>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  )
}
