import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import '../components/css/Login.css'
import background from '../assets/loginbackground.png'
import inputfield from '../assets/inputfield.png'
import submitbtn from '../assets/submitbtn.png'

export default function Login(props) {


    const navigate = useNavigate();
    // const host = "http://localhost:5000";
    let host = process.env.REACT_APP_API_REQUEST;
    const [loader, setLoader] = useState(true);
    const [errorMassage, setErrorMassage] = useState('');


    const [credential, setCredential] = useState({ email: '', password: '' });

    const loginSubmit = async (e) => {

        e.preventDefault();
        if (credential.email === "") {
            return document.getElementById('viewalert').innerText = "* email not blank";
        }
        if (credential.password.length <= 4) {
            return document.getElementById('viewalert').innerText = "* password lenght more than 5 character";
        }

        const eresponse = await fetch(`${host}/auth/findemail`, {
            method: 'GET',
            headers: {
                'email': credential.email
            }
        });
        const emailExited = await eresponse.json();
        if (emailExited.success) {
            return document.getElementById('viewalert').innerText = "* Email Can't Find.";
        }


        setLoader(false);

        const response = await fetch(`${host}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: credential.email, password: credential.password })
        });
        const json = await response.json();
        setLoader(true);
        if (json) {
            console.log(json);
            if (json.errors) {
                setCredential({password:''})
                setErrorMassage('password inCorrect')
            } else {
                localStorage.setItem('token', json.authToken)
                setLoader(true);
                navigate('/');
                props.myMessage('Welcome back! Let the adventures continue...')
            }
        }
    }

    
    const onChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value })
    }

    const showPass = (id) => {
        if (document.getElementById(id).type === 'text') {
            document.getElementById(id).type = 'password';
        } else {
            document.getElementById(id).type = 'text';
        }
    }
    return (
        <>
            <section className="l-section" >
                <div className="l-form-box">
                    <div className="l-form-value">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            {loader ?
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={background} className='login_back_img'  alt="" />
                                        <div className='l_from_set'>
                                            <div className='l_input_col'>
                                                <img src={inputfield} className='login_input_back_img' alt="" />
                                                <input id="email" value={credential.email} type='email' onChange={onChange} name="email" placeholder='Email' />
                                            </div>
                                            <div className='l_input_col'>
                                                <img src={inputfield} className='login_input_back_img' alt="" />
                                                <input id="password" value={credential.password} type='password' onChange={onChange} name="password" className='input_field' placeholder='password' />
                                                <span class="material-symbols-outlined passiconStyle" onClick={()=> showPass('password')}>visibility</span>
                                            </div>
                                            <div>
                                                <p id='viewalert' style={{ color: 'red' }}>{errorMassage}</p>
                                            </div>
                                            <div className='l_submit_set' onClick={loginSubmit} >
                                                <img src={submitbtn} width={150} alt="" />
                                                <p style={{ position: 'absolute' }}>Let's Go</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            
                                : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                                    <div className='spinner_data'> </div>
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
