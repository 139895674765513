import React from 'react'
import { useState, useEffect } from "react";
import person from "../../assets/person_logo_gamer.png"
import reactangle from "../../assets/reactange.png"
import btnshow from "../../assets/btnedit.png"
import moreoption from '../../assets/moreoption.png'
import logo from "../../assets/web logo Resize.png"
import submitbtn from "../../assets/submitbtn.png"
import gamer_back from "../../assets/gamer_back.png"
import Header from '../Header'
import Footer from '../Footer'
import '../css/EditProfile.css'
import { useNavigate, Link } from 'react-router-dom';
import Nav_gamer from './Nav_gamer';

export default function Profile() {

    const [image, setImage] = useState(null);
    const [user, setUser] = useState();
    const [bg, setBg] = useState(reactangle);
    const [picurl, setPicurl] = useState();
    const host = process.env.REACT_APP_API_REQUEST;
    const navigate = useNavigate();

    const showupload = () => {
        document.getElementById('pop_updateload').classList.toggle("disnone");
    }

    const closeUploadpop = () => {
        document.getElementById('pop_update').classList.toggle("disnone");
    }
    const showUploadPop = () => {
        document.getElementById('pop_updateload').classList.toggle("disnone");
        document.getElementById('pop_update').classList.toggle("disnone");
    }
    const showpicPop = () => {
        document.getElementById('userpic').click();
        document.getElementById('pop_update').classList.toggle("disnone");
        document.getElementById('pop_submit_pic').classList.toggle("disnone");
    }
    const closeSubmiPic = () => {
        document.getElementById('pop_submit_pic').classList.toggle("disnone");
    }
    const setBackground = () => {
        document.getElementById('pop_submit_pic').classList.toggle("disnone");
        document.getElementById('pop_submit_background').classList.toggle("disnone");
    }
    const closeBackground = () => {

        document.getElementById('pop_submit_background').classList.toggle("disnone");
    }

    const fetchImage = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        setUser(userid);
        if (userid) {
            const response = await fetch(`${host}/pic/getpic`, {
                method: 'GET',
                headers: {
                    'userid': userid._id
                }
            });
            const json = await response.json();
            if (json.length === 1) {
                setPicurl(json[0].picurl)
            }
        }
    }

    const setGamerImage = (e) => {
        setImage(e.target.files[0])
        var imageset = document.getElementById('gamerPicid');
        imageset.src = URL.createObjectURL(e.target.files[0]);

        var imageset2 = document.getElementById('gamercardPic');
        imageset2.src = URL.createObjectURL(e.target.files[0]);
    }


    useEffect(() => {
        fetchImage();
    }, [])


    const changeBackground = (e) => {
        setBg(gamer_back)
        console.log(e);
    }

    const submitUserBgPic = async () => {
        document.getElementById('pop_submit_background').classList.toggle("disnone");

        const data = new FormData();

        data.append("file", image);
        data.append("upload_preset", "gamer_adda");
        data.append("cloud_name", "dw5zqoz0n");

        try {
            if (image === null) {
                return console.log('work..');
            }

            const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
                method: "POST",
                body: data
            })

            const cloudData = await res.json();
            const uresponse = await fetch(`${host}/auth/getuser`, {
                method: 'GET',
                headers: {
                    'auth-token': localStorage.getItem('token')
                }
            });
            const userid = await uresponse.json();

            const response = await fetch(`${host}/pic/createpic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userPic: cloudData.url, userId: userid._id, picWidth: "100" })
            });
            const json = await response.json();

            if (json) {

                const responseb = await fetch(`${host}/banner/createbanner`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ userbanner: "1", userId: userid._id, usertype: userid.whois })
                });
                const json2 = await responseb.json();


                navigate('/editgamer/bio');
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Header />
            <div>
                <br />
                <br />
                <br />
                <br />
            </div>


            <div>
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                        <Nav_gamer/>
                    </div>
                    {/* <div>
                    {picurl?  <img width={200} style={{borderRadius:'50%'}} src={picurl} alt="" />: <img width={200} src={person} alt="" />}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                    {user? user.username  : "-" }
                    </div> */}
                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>

                        <div className=" profile_nav_white profile_header" >
                            Bio
                        </div>

                        <Link to="/editgamer/achievements" style={{ textDecoration: 'none' }}>
                            <div className=" profile_header"  >
                                ACHIEVEMENTS
                            </div>
                        </Link>



                        <Link to="/editgamer/events" style={{ textDecoration: 'none' }}>
                            <div className="profile_header" >
                                EVENTS
                            </div>
                        </Link>
                        <Link to="/editgamer/profile" style={{ textDecoration: 'none' }}>
                            <div className="profile_header" >
                                EDIT DETAILS
                            </div>
                        </Link>

                    </div>
                </div>

            </div>



      

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </>
    )
}
