import React from 'react'
import bioback from "../../assets/sponsorbioback.png"
import Footer from '../../components/Footer'
import gamers from '../../assets/gamers.png'
import "../../components/css/Profile.css"
import { useState, useEffect } from "react";
import Header from '../Header'
import { useParams } from 'react-router-dom';
import person from '../../assets/person.jpg'
import { useNavigate, Link } from 'react-router-dom';
import Chatbox_back from "../../assets/Chatbox_back.png"
// import { io } from 'socket.io-client';
import ScrollableFeed from 'react-scrollable-feed'
import typeLoad from "../../assets/coming soon .png"

// const socket = io.connect('http://localhost:5000');
// const socket = io(process.env.REACT_APP_API_REQUEST, { forceNew: true });
// const socket = io.connect(process.env.REACT_APP_API_REQUEST);
// const socket = io.connect("wss://gamer-adda-backend.vercel.app");

export default function GamerMessage() {

    const [message, setMessage] = useState();
    const [chatMessage, setChatMessage] = useState([]);
    const [socketConnect, setSocketConnect] = useState(false);
    const [count, setcount] = useState(4);
    let { gamerid } = useParams();
    const [user, setUser] = useState();
    const [bio, setBio] = useState();
    const [event, setEvent] = useState([]);
    const [achievement, setAchievement] = useState([]);
    const host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const [loader, setLoader] = useState(false);
    const [picurl, setPicurl] = useState();
    const [mychat, setMychat] = useState(false);

    const [chatIdRoom, setChatIdRoom] = useState();
    const [typing, setTyping] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const fetchBio = async () => {
        const response = await fetch(`${host}/userbio/getbio`, {
            method: 'GET',
            headers: {
                'userid': gamerid
            }
        });
        const json = await response.json();
        if (json.length === 1) {
            setBio(json[0].userBio);
        }

        setLoader(true)
    }

    const fetchImage = async () => {

        const response = await fetch(`${host}/pic/getpic`, {
            method: 'GET',
            headers: {
                'userid': gamerid
            }
        });
        const json = await response.json();
        console.log(json);
        if (json.length === 1) {
            // setCredential({ userbio:  });
            console.log(json[0].picurl);
            setPicurl(json[0].picurl)
        }
        // setLoader(true);

    }
    const fetchUser = async () => {
        const uresponse = await fetch(`${host}/auth/findid`, {
            method: 'GET',
            headers: {
                'userid': gamerid
            }
        });
        const json = await uresponse.json();
        if (json) {
            setUser(json)
        }
    }

    const fetchChat = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();

        const response = await fetch(`${host}/chat/getchatid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ myId: userid._id, friendId: gamerid })
        });
        const json = await response.json();
        if (json) {
            if (json.length === 0) {
                setMychat(true)

                
            }else{

          
            // console.log(json[0]._id);
            setChatIdRoom(json[0]._id)
            const uresponse = await fetch(`${host}/message/view`, {
                method: 'GET',
                headers: {
                    'chatid': json[0]._id
                }
            });
            const message = await uresponse.json();
            console.log(message);
            setChatMessage(message);
            // socket.emit('join chat',json[0]._id);
        }
        }

    }



    const sumbitMessage = async (e) => {
        e.preventDefault();
        console.log(message);
        // socket.emit('stop typing',chatIdRoom)

        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();

        const response = await fetch(`${host}/chat/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ myId: userid._id, friendId: gamerid, latestMessage: message })
        });
        const json = await response.json();
        if (json) {
            const response2 = await fetch(`${host}/message/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sender: userid._id, chatId: json._id, content: message })
            });
            const json2 = await response2.json();
            if (json2) {
                setMessage('');
                document.getElementById('innermesvaluegamer').value = '';
                console.log(json2);
                console.log('gamer');
                setChatMessage(chatMessage => [...chatMessage, json2])
                // socket.emit("new message",json2)
            }
        }
    }



 
    // useEffect(() => {
    //     const handleMessage = (data) => {
    //         console.log(chatMessage);
    //         setChatMessage(chatMessage => [...chatMessage, data])
    //         // setChatMessage([...chatMessage, data])
    //         console.log("recieved data");
    //         // document.getElementById('chatinner').innerHTML += `  <div style="display:flex;">${data.message}</div>`
    //     };

    //     socket.on("message recieved", handleMessage);
    //     return () => {
    //         socket.off("message recieved", handleMessage);
    //     };
    // }, [socket]);





    

    // useEffect(() => {
    // //   socket = io(socket);
    //   socket.emit("setup",gamerid);
    //   socket.on("connected",()=> setSocketConnect(true))
    //   socket.on('typing',()=>setIsTyping(true))
    //   socket.on('stop typing',()=>setIsTyping(false))

    // }, []);

    useEffect(() => {

        fetchUser();
        fetchImage();
        fetchBio();
        fetchChat();


    }, [])

    // const typingHanlder = (e)=>{
    //     setMessage(e.target.value)

    //     if(!socketConnect) return;

    //     if (!typing) {
            
    //         setTyping(true)
    //         socket.emit('typing',chatIdRoom)
    //     }
        
    //     let lastTypingTime = new Date().getTime()
    //     var timerLenght = 3000;
        
    //     setTimeout(() => {
    //         var timeNow = new Date().getTime();
    //         var timeDiff  = timeNow - lastTypingTime
    //         if(timeDiff >= timerLenght && typing){
    //             socket.emit("stop typing", chatIdRoom);
    //             setTyping(false);
    //         }
    //     }, timerLenght);

    // }


    return (
        <>

            <Header />
            <section>
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>


                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                        {picurl ? <img width={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img width={200} style={{ borderRadius: '50%' }} src={person} alt="" />}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                        {user ? user.username : '-'}
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>

                        <Link to={`/gamerprofile/bio/${gamerid}`} style={{ textDecoration: 'none' }}>
                            <div className={count === 1 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                                Bio
                            </div>
                        </Link>
                        <Link to={`/gamerprofile/achievement/${gamerid}`} style={{ textDecoration: 'none' }}>
                            <div className={count === 2 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                                Achievements
                            </div>
                        </Link>
                        <Link to={`/gamerprofile/event/${gamerid}`} style={{ textDecoration: 'none' }}>
                            <div className={count === 3 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                                Events
                            </div>
                        </Link>
                        <div className={count === 4 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                            Message
                        </div>
                    </div>
                </div>

                <div className='coming_soon_style'>
                    <img src={typeLoad}  alt="" />
                </div>

                {/* {loader ?
                    <div>  {mychat? <div style={{color:'white',textAlign:'center'}}> this is your chat you cont enter any thing...</div> :  
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ color: 'white', textAlign: 'center', width: '1000px', fontSize: '25px' }}>
                               

                                <div style={{ height: '400px', overflow: 'auto' }}>

                                <ScrollableFeed>
                                    {chatMessage.map((element, myid) => {
                                        if (element.sender === gamerid) {

                                            return <div key={myid} style={{ display: "flex" }}>{element.content}</div>
                                        } else {
                                            return <div key={myid} style={{ display: "flex", justifyContent: "flex-end" }}>{element.content}</div>

                                        }
                                    })}
                                    </ScrollableFeed>


                                </div>
                                <div>
                                {isTyping?<div style={{display:'flex'}}><img src={typeLoad}width={50} style={{borderRadius:'20px'}} alt="" /></div>:<div style={{opacity:'0'}}><img src={typeLoad}width={50} style={{borderRadius:'20px'}} alt="" /></div>}
                                    <form onSubmit={sumbitMessage}>

                                        <input className='sp_in_input' type="text" name="" id="innermesvaluegamer" onChange={typingHanlder} />
                                    </form>
                                </div>
                            </p>
                        </div>}
                    </div> : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                        <div className='spinner_data'> </div>
                    </div>
                } */}




                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>

                <Footer />

            </section>
        </>
    )
}
