import React from 'react'
import logo from '../assets/web logo Resize.png'
import person from '../assets/person.jpg'
import sposnsorcard from '../assets/sponsorback.png'
import savesponsor from '../assets/savesponsor.png'
import '../components/css/Explore.css'
import Footer from '../components/Footer'
import '../components/css/Header.css'
import { Link } from 'react-router-dom';
import Header from '../components/Header'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function Sponsors(props) {
  const navigate = useNavigate();
  const [amount, setAmount] = useState([])
  const [sponsor, setSponsor] = useState([])
  const [exp, setExp] = useState([])
  const [loader, setLoader] = useState(false);

  // const host = "http://localhost:5000";

  const host = process.env.REACT_APP_API_REQUEST;

  // const userData = async () => {

  //   const uresponse = await fetch(`${host}/amount/allamount`, {
  //     method: 'GET',
  //   });
  //   const userid = await uresponse.json();

  //   setAmount(userid);

  // }

  const sponsors = async () => {
    const uresponse = await fetch(`${host}/getuser/sponsor`, {
      method: 'GET',
    });
    const userdata = await uresponse.json();
    // console.log(userdata[0][0][0]._id);
    // console.log(userdata[0][1][0].userBio);
    // console.log(userdata[0][2][0].picurl);
    // console.log(userdata[0][3][0].bannerurl);

    // console.log(userdata[0][4].length);

    setSponsor(userdata)
    setLoader(true)
  }




  useEffect(() => {
    // userData();
    if ( !localStorage.getItem('token')) {
      navigate('/');
      console.log('not access...');
      props.myMessage('oops! Please Sign in to access more features.')
    }
   
    sponsors();
  }, [])




  return (
    <>
      <Header setHeadProgress={props.setProgress} />

      <section>

        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>

          {loader ?
            <div style={{ width: '1900px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

              

              {sponsor.map((element, index) => {
                if (element[4].length !== 0) {
                  return <Link style={{ textDecoration: 'none' }} key={index} to={`/sponsorprofile/bio/${element[0][0]._id}`}>


                    <div className='card_box_sponsor'>

                      <div className='card_save' >
                        <img src={savesponsor} alt="" />
                      </div>

                      <div className='card_background_img'>
                        <img style={{ borderRadius: '15px' }} width={550} height={268} src={element[3][0].bannerurl} alt="" />
                      </div>


                      <div className='card_down_cont' >
                        <div className='card_logo_cont'>
                          {element[2][0].picurl ? <img src={element[2][0].picurl} width={40} /> : <img src={person} width={40} alt="" />}
                          {/* <img src={element[2][0].picurl} width={40} alt="" /> */}
                          <div style={{display:'flex',alignItems:"center"}}>
                            <p style={{ margin: "0px", fontSize: '16px' }}>We are Gamers Adda. At Gamers Adda, we connect Gamers and Sponsors. Our goal is...</p>
                          </div>
                        </div>
                        <div className='card_ready_spon' style={{display:'flex',alignItems:"center" ,justifyContent:'center'}}>
                          <p style={{ margin: "0px", fontSize: '12px' }}>Ready to Sponsor</p>
                          <p style={{ margin: "0px" }}>200,000</p>
                        </div>
                      </div>

                      <div className='hover_card_effect' >
                        {element[2][0].picurl ? <img src={element[2][0].picurl} width={100} height={80} /> : <img src={person} width={100} height={90} alt="" />}

                        <div>We are Gamers Adda. At Gamers Adda, we connect Gamers and Sponsors. Our goal is to make it easier for gamers and Gaming Enthusiasts to organize gaming events, tournaments and more. We are looking for potential Gamers who can promote our brand through Gaming-related events.</div>
                        <p style={{ margin: "0px", marginTop: '10px' }}>Ready to Sponsor</p>
                        <p style={{ margin: "0px" }}>200,000</p>
                      </div>
                    </div>

                  </Link>
                }

              })}

            </div>
            : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
              <div className='spinner_data'></div>
            </div>}
        </div>

























        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>


      </section>

      <Footer />

    </>
  )
}
