import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import comingsoon from "../assets/light coming soon .png"

export default function About(props) {
  return (
    <>
      <Header setHeadProgress={props.setProgress} />

      <div>


        <br />
        <br />
        <br />
        <div style={{ textAlign: 'center', marginTop: '11px' }}>

          <img src={comingsoon} className='about_img_here' alt="" />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  )
}
