import React from 'react'
import { useState, useEffect } from "react";
import budgutback from "../../assets/sp_budget.png"
import budgutamount from "../../assets/Buttons2.png"
import budgutamountok from "../../assets/apply button black 2.png"
import budgutamountback from "../../assets/sp_budget_back_amount.png"
import repeys from "../../assets/rupeys.png"
import sponsors from '../../assets/web logo Resize.png'
import person from "../../assets/person_logo_gamer.png"
import '../css/Budget.css'

import { useNavigate, Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Nav_sponsor from './Nav_sponsor';

export default function Budget() {

  const navigate = useNavigate();
  const [amount, setAmount] = useState([]);
  const [loader, setLoader] = useState(false);
  const [credential, setCredential] = useState({ amount: '', setId: '', userbio: '', deleteId: '' });
  const host = process.env.REACT_APP_API_REQUEST;
  const [user, setUser] = useState();
  const [picurl, setPicurl] = useState();
  const [loadpage, setLoadpage] = useState('');

  // const host = "http://localhost:5000";

  const addBudget = () => {
    document.getElementById('sp_add_budget').classList.toggle("disnone");
  }

  const addAmount = async () => {
    if (credential.amount !== '') {


      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();
      if (userid) {
        const response = await fetch(`${host}/amount/createamount`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ amount: credential.amount, userId: userid._id })
        });
        const json = await response.json();
        document.getElementById('sp_add_budget_delete').classList.toggle("disnone");
        
        window.location.reload();
      }
    }
  }


  const deleteAmount = async () => {

    const response = await fetch(`${host}/amount/deleteamount`, {
      method: 'POST',
      headers: {
        'amountid': credential.deleteId
      },

    });
    const json = await response.json();
    console.log(credential.deleteId);
    // document.getElementById('sp_add_budget_delete').classList.toggle("disnone");
 
    window.location.reload();

  }


  const onChange = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value })
  }
  const updateAmount = (id, amount) => {
    document.getElementById('sp_add_budget_update').classList.toggle("disnone");
    setCredential({ amount: amount, setId: id })
  }

  const handleDeleteAmount = (id, amount) => {
    document.getElementById('sp_add_budget_delete').classList.toggle("disnone");
    setCredential({ amount: amount, deleteId: id })
  }

  const updateAmounttoggle = () => {
    document.getElementById('sp_add_budget_update').classList.toggle("disnone");

  }
  const deleteAmounttoggle = () => {
    document.getElementById('sp_add_budget_delete').classList.toggle("disnone");

  }
  const handleupdate = async () => {
    const response = await fetch(`${host}/amount/updateamount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: credential.setId, amount: credential.amount })
    });
    const json = await response.json();
    window.location.reload();
    
  }


  const fetchAmount = async () => {
    const uresponse = await fetch(`${host}/auth/getuser`, {
      method: 'GET',
      headers: {
        'auth-token': localStorage.getItem('token')
      }
    });
    const userid = await uresponse.json();
    setUser(userid);
    if (userid) {
      const response = await fetch(`${host}/amount/getamount`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json = await response.json();
      setAmount(json);
      setLoader(true)
      const response2 = await fetch(`${host}/userbio/getbio`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json2 = await response2.json();
      setCredential({ userbio: json2.userBio });
    }
  }



  const fetchImage = async () => {
    const uresponse = await fetch(`${host}/auth/getuser`, {
      method: 'GET',
      headers: {
        'auth-token': localStorage.getItem('token')
      }
    });
    const userid = await uresponse.json();
    if (userid) {
      const response = await fetch(`${host}/pic/getpic`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json = await response.json();
      if (json.length === 1) {
        setPicurl(json[0].picurl)
      }

    }
  }
  useEffect(() => {
    fetchImage();
    fetchAmount();
  }, [])

  return (
    <>

      <Header />

      <div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <div>
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <Nav_sponsor />
          {/* <div>
            {picurl ? <img width={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img width={200} src={person} alt="" />}
          </div>
          <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
            {user ? user.username : "-"}
          </div> */}
          <div style={{ marginTop: '15px' }}>
            <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
          </div>
        </div>

        <div className='profile_section'>
          <div className='profile_sec'>

            <Link to="/editsponsor/bio" style={{ textDecoration: 'none' }}>
              <div className=" profile_header"  >
                Bio
              </div>
            </Link>

            <div className=" profile_nav_white profile_header" >
              Budget
            </div>

            <Link to="/editsponsor/expect" style={{ textDecoration: 'none' }}>
              <div className="profile_header" >
                Expectations
              </div>
            </Link>
            {/* <Link to="/editsponsor/profile" style={{ textDecoration: 'none' }}>
              <div className="profile_header" >
                Edit DETAILS
              </div>
            </Link> */}

          </div>
        </div>

      </div>


      {loader ?
        <div className='sp_budget_id'>
          <p style={{ color: 'white', textAlign: 'center', width: '1000px', fontSize: '25px' }}>
            Add Budget
          </p>
          <div onClick={addBudget}>
            <div className='sp_budget_add'>
              +
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
            {amount.map((element, myid) => {

              return <div key={element} className='card_budget_set'>
                <img width={500} src={budgutamountback} alt="" />
                <div style={{ position: 'absolute', textAlign: 'center' }}>
                  <div className='card_more_options'>
                    <span class="material-symbols-outlined" onClick={() => { updateAmount(element._id, element.amount) }}>edit</span>
                    <span class="material-symbols-outlined" onClick={() => { handleDeleteAmount(element._id, element.amount) }}>delete</span>
                  </div>
                  <div className='card_more_details'>
                    <img width={100} src={repeys} alt="" style={{ display: 'none' }} />
                    <p style={{ fontSize: '30px' }}>We are ready to sponsor <br />{element.amount}</p>
                  </div>
                </div>
              </div>

            })}
          </div>
        </div>
        : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
          <div className='spinner_data'></div>
        </div>
      }






      <br />
      <br />
      <br />
      <br />
      <br />
      <br />






      <section id='sp_add_budget' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='close_bio_sp' style={{position:'fixed',top:'34%',right:'34%'}}><span onClick={addBudget} class="material-symbols-outlined">close</span></div>
          <img width={500} src={budgutback} alt="" />

          <div className='sp_add_img_here' style={{ marginTop: '-265px' }}>
            <div style={{ display: 'flex' }}>

              <img width={400} src={budgutamount} alt="" />
              <input id="amount" value={credential.amount} autocomplete="off" style={{ marginTop: '28px' }} type='text' onChange={onChange} name="amount" className='sp_add_input' placeholder='Amount' />
            </div>
            <div className='sp_add_btn'>
              <img width={150} src={budgutamountok} alt="" />
              <div onClick={addAmount}>Ok</div>
            </div>
          </div>

        </div>
      </section>


      <section id='sp_add_budget_update' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='close_bio_sp' style={{position:'fixed',top:'34%',right:'34%'}}><span onClick={updateAmounttoggle} class="material-symbols-outlined">close</span></div>
          <img width={500} src={budgutback} alt="" />
          <div className='sp_add_img_here'  style={{ marginTop: '-265px' }}>
            <div style={{ display: 'flex' }}>
              <img width={400} src={budgutamount} alt="" />
              <input id="amount" value={credential.amount} autocomplete="off" style={{ marginTop: '28px' }} type='text' onChange={onChange} name="amount" className='sp_add_input' placeholder='Amount' />
            </div>
            <div className='sp_add_btn'>
              <img width={150} src={budgutamountok} alt="" />
              <div onClick={handleupdate}>Ok</div>
            </div>
          </div>
        </div>
      </section>

      <section id='sp_add_budget_delete' className='sp_budget_add_id disnone'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>

          <div className='close_bio_sp' style={{position:'fixed',top:'34%',right:'34%'}}><span onClick={deleteAmounttoggle} class="material-symbols-outlined">close</span></div>
          <div className='card_delete_confirm'>
            <p>Are you sure want to delete that{credential.amount}</p>
            <div>
              <button className='confirm_delete_btn' onClick={deleteAmount}>Ok</button>
            </div>
          </div>
        </div>
      </section>


      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>


      <Footer />
    </>
  )
}
