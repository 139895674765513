import React from 'react'
import Header from '../Header'
import Footer from '../Footer'

import person from '../../assets/person.jpg'
import { useState, useEffect } from "react";
import budgutback from "../../assets/sp_budget_back_amount.png"
import budgutamountback from "../../assets/sp_budget_back_amount.png"
import repeys from "../../assets/rupeys.png"


import { useParams } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';

export default function SponsorBudget() {



    const [amount, setAmount] = useState([]);
    const [user, setUser] = useState();

    let { sponsorid } = useParams();
    const host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const [loader, setLoader] = useState(false);
    const [count, setcount] = useState(2);
    const [picurl, setPicurl] = useState();

    const fetchUser = async () => {
        const uresponse = await fetch(`${host}/auth/findid`, {
            method: 'GET',
            headers: {
                'userid': sponsorid
            }
        });
        const json = await uresponse.json();
        if (json) {
            setUser(json)
        }
    }


    const fetchAmount = async () => {

        const response = await fetch(`${host}/amount/getamount`, {
            method: 'GET',
            headers: {
                'userid': sponsorid
            }
        });
        const json = await response.json();
        setAmount(json);
        setLoader(true);
    }

    const fetchImage = async () => {

        const response = await fetch(`${host}/pic/getpic`, {
            method: 'GET',
            headers: {
                'userid': sponsorid
            }
        });
        const json = await response.json();
        console.log(json);
        if (json.length === 1) {
            // setCredential({ userbio:  });
            console.log(json[0].picurl);
            setPicurl(json[0].picurl)
        }
        // setLoader(true);

    }


    useEffect(() => {
        fetchImage();
        fetchUser();
        fetchAmount();

    }, [])










    return (
        <>
            <Header />


            <section>
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>


                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                        {picurl ? <img width={200} height={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img style={{ borderRadius: '50%' }}  width={200} src={person} alt="" />}
                        {/* <img width={200} src={sponsors} alt="" /> */}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                        {user ? user.username : '-'}
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>
                        <Link to={`/sponsorprofile/bio/${sponsorid}`} style={{ textDecoration: "none" }}>
                            <div className={count === 1 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                                Bio
                            </div>
                        </Link>
                        <div className={count === 2 ? 'profile_nav_white profile_header' : 'profile_header'}>
                            Budget
                        </div>
                        <Link to={`/sponsorprofile/expectation/${sponsorid}`} style={{ textDecoration: "none" }}>
                            <div className={count === 3 ? 'profile_nav_white profile_header' : 'profile_header'}>
                                Expectations
                            </div>
                        </Link>
                        <Link to={`/sponsorprofile/massage/${sponsorid}`} style={{ textDecoration: "none" }}>
                            <div className={count === 4 ? 'profile_nav_white profile_header' : 'profile_header'}>
                                Message
                            </div>
                        </Link>
                    </div>
                </div>


                {loader ?
                    <div style={{marginTop:'92px'}}>
                        {amount ?
                            <div className='sp_budget_id'>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    {amount.map((element, myid) => {
                                        return <div key={element} style={{ display: 'flex', flexDirection: 'column', padding: "10px", cursor: 'pointer' }} >
                                            <img width={500} src={budgutback} alt="" style={{ opacity: '0' }} />
                                            <div id='sp_added_data' className='sp_add_img_here '>
                                                <div className='sp_edit_data_here' style={{ opacity: '0' }}>
                                                    <span class="material-symbols-outlined">edit</span>
                                                    <span>Edit</span>
                                                </div>
                                                <img width={400} src={budgutamountback} alt="" />
                                                <img width={100} style={{ marginTop: '-225px' }} className='sp_repeys' src={repeys} alt="" />
                                                <p style={{ marginTop: '30px' }}>Sponsored amount {element.amount}</p>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                                <div className='spinner_data'> </div>
                            </div>}
                    </div> : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                        <div className='spinner_data'> </div>
                    </div>}


                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>


                <Footer />

            </section>

        </>
    )
}
