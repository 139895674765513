import React from 'react'
import './css/Notify.css'

export default function Notify({alert}) {
    // const [massage, setmassage] = useState(myMessage)
    return (
        <>
            {alert?
            <div className='notify_cont'>
                <div className='notify_cont_innner'>
                    <b>{alert}</b>
                </div>
                <div className='notify_donw_line'></div>
            </div>
            :''}
        </>
    )
}
