import React, { useState, useEffect } from 'react'
import person from "../../assets/person_logo_gamer.png"
import btnshow from "../../assets/btnedit.png"
import picback from "../../assets/sp_expection_back.png"
import okbtn from "../../assets/sp_budget_ok.png"
import logo from "../../assets/web logo Resize.png"
import submitbtn from "../../assets/submitbtn.png"
import sposnsorcard from '../../assets/background_forcard_demo.png'
import savesponsor from '../../assets/savesponsor.png'
import '../css/EditProfile.css'
import Header from '../Header'
import Footer from '../Footer'
import { useNavigate, Link } from 'react-router-dom';
import blackbtn from "../../assets/blackbtn.png"



export default function Nav_sponsor() {
  const [image, setImage] = useState(null);
  // const host = "http://localhost:5000";
  const host = process.env.REACT_APP_API_REQUEST;
  const [user, setUser] = useState();
  const [picurl, setPicurl] = useState();
  const navigate = useNavigate();

  const showupload = () => {
    document.getElementById('pop_updateload').classList.toggle("disnone");
  }

  const showUploadPic = () => {
    document.getElementById('pop_updateload').classList.toggle("disnone");
    document.getElementById('pop_update_pic').classList.toggle("disnone");
  }

  const closeUploadpic = () => {
    document.getElementById('pop_update_pic').classList.toggle("disnone");
  }
  const closeSubmitpic = () => {
    document.getElementById('pop_submit_pic').classList.toggle("disnone");
  }



  const showSubmitPop = () => {
    document.getElementById('userpic').click();
    document.getElementById('pop_update_pic').classList.toggle("disnone");
    document.getElementById('pop_submit_pic').classList.toggle("disnone");
  }

  const showSubmitBanner = () => {
    document.getElementById('userbanner').click();
    document.getElementById('pop_updateload').classList.toggle("disnone");
    document.getElementById('pop_submit_banner').classList.toggle("disnone");
  }
  const closeSubmitBanner = () => {
    document.getElementById('pop_submit_banner').classList.toggle("disnone");
  }



  const setPicWidth = async () => {
    document.getElementById('pop_submit_pic').classList.toggle("disnone");
    console.log(image);


    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "gamer_adda");
    data.append("cloud_name", "dw5zqoz0n");

    try {
      if (image === null) {
        return console.log('work..');
      }

      const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
        method: "POST",
        body: data
      })

      const cloudData = await res.json();
      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();

      const response = await fetch(`${host}/pic/createpic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userPic: cloudData.url, userId: userid._id, picWidth: "100" })
      });
      const json = await response.json();
      navigate('/editsponsor/bio');

    } catch (error) {
      console.log(error);
    }
  }




  const setbackgroundImage = (e) => {
    setImage(e.target.files[0])
    var imageset = document.getElementById('card_background');
    imageset.src = URL.createObjectURL(e.target.files[0]);
  }

  const userImage = (e) => {
    setImage(e.target.files[0])
    var imageset = document.getElementById('userPic');
    imageset.src = URL.createObjectURL(e.target.files[0]);
  }




  const submitBanner = async () => {
    document.getElementById('pop_submit_banner').classList.toggle("disnone");
    console.log(image);

    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "gamer_adda");
    data.append("cloud_name", "dw5zqoz0n");

    try {
      if (image === null) {
        return console.log('work..');
      }

      const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
        method: "POST",
        body: data
      })

      const cloudData = await res.json();
      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();

      const response = await fetch(`${host}/banner/createbanner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userbanner: cloudData.url, userId: userid._id, usertype: userid.whois })
      });
      const json = await response.json();
      navigate('/sponsors');

    } catch (error) {
      console.log(error);
    }

  }


  const fetchImage = async () => {
    const uresponse = await fetch(`${host}/auth/getuser`, {
      method: 'GET',
      headers: {
        'auth-token': localStorage.getItem('token')
      }
    });
    const userid = await uresponse.json();
    setUser(userid);
    if (userid) {
      const response = await fetch(`${host}/pic/getpic`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json = await response.json();
      console.log(json);
      if (json.length === 1) {
        // setCredential({ userbio:  });
        console.log(json[0].picurl);
        setPicurl(json[0].picurl)
      }
      // setLoader(true);
    }
  }


  useEffect(() => {
    fetchImage();
  }, [])



  return (
    <>



      <div>
        <br />
        <br />
      </div>

      <div className='ep_cont_top'>
        <div style={{ cursor: 'pointer' }} className='ep_person_cont' onClick={showupload}>
          {picurl ? <img width={200} height={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img width={200} src={person} alt="" />}
          {/* <img width={200} src={person} alt="" /> */}
          <div className='ep_edit_person_icon'>
            <span class="material-symbols-outlined">edit</span>
            <span>Edit</span>
          </div>
        </div>
        <div style={{ fontSize: '34px' }}>
          {user ? user.username : "-"}
          {/* <b>Muthu</b> */}
        </div>
      </div>


      <div id='pop_updateload' className='disnone'>
        <div className='ep_banner_pic'>
          <span class="material-symbols-outlined ep_close hoverColorChangeVoilet" onClick={showupload}>close</span>

          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', cursor: 'pointer' }} onClick={showUploadPic}>
            <img src={btnshow} alt="" style={{ width: "550px", margin: '50px' }} />
            <p className='hoverColorChangeVoilet' style={{ position: 'absolute' }}>Upload Profile Pic</p>
          </div>

          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', cursor: 'pointer' }} onClick={showSubmitBanner}>
            <img src={btnshow} alt="" style={{ width: "550px", margin: '50px' }} />
            <p style={{ position: 'absolute' }} className='hoverColorChangeVoilet'>Upload Banner</p>
          </div>
        </div>
      </div>

      <div id='pop_update_pic' className='disnone'>
        <div className='ep_banner_pic'>
          <span class="material-symbols-outlined ep_close" onClick={closeUploadpic}>close</span>

          <div className='ep_pop_pic'>
            <img src={picback} width={600} alt="" />
            <div style={{ position: 'absolute', marginLeft: '57px' }}>
              <div style={{ fontSize: '21px', textAlign: "center" }}>
                {/* <p>Notification</p> */}
                <p>Set your company logo (PNG only)</p>
              </div>
              {/* <div className='ep_pop_pic_submit_btn' onClick={showSubmitPop}>
                <img src={okbtn} width={100} alt="" />
                <p style={{ position: "absolute", color: 'white', fontSize: "22px" }}>OK</p>
              </div> */}
              <div onClick={showSubmitPop} style={{ cursor: 'pointer', marginLeft: '-21px' }}>
                <img width={150} src={blackbtn} alt="" />

                <p style={{ color: 'white', marginTop: '-40px', textAlign: 'center', cursor: 'pointer' }}>OK</p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div id='pop_submit_pic' className='disnone'>
        <div className='ep_banner_pic'>
          <span class="material-symbols-outlined ep_close" onClick={closeSubmitpic}>close</span>
          <div style={{ position: "fixed", top: "0", left: '1px' }}>
            <input type="file" id='userpic' accept="image/png" onChange={userImage} />
          </div>
          <div>
            <div className='ep_pic_set_back'>
              <img src={person} width={100} alt="" id='userPic' />
            </div>
            <div>
              <input type="range" id="vol" name="vol" min="0" max="50" />
            </div>
            <div className='ep_submit_pic' onClick={setPicWidth}>
              <img src={submitbtn} alt="" />
              <p style={{ position: 'absolute', fontSize: '20px' }}>Submit</p>
            </div>
          </div>
        </div>
      </div>

      <div id='pop_submit_banner' className='disnone'>
        <div className='ep_banner_pic'>
          <span class="material-symbols-outlined ep_close" onClick={closeSubmitBanner}>close</span>
          <div style={{ position: "fixed", top: "0", left: '1px' }}>
            <input type="file" id='userbanner' accept="image" onChange={setbackgroundImage} />
          </div>
          <div>
            <div>
              <div className='card_box_sponsor'>
                <div className='card_save' >
                  <img src={savesponsor} alt=""  style={{opacity:'0'}}/>
                </div>
                <div className='card_background_img'>
                  <img style={{ borderRadius: '15px' }} id='card_background' width={550} height={268} src={sposnsorcard} alt="" />
                </div>
                <div className='card_down_cont' style={{opacity:'1',marginTop:'-94px'}} >
                  <div className='card_logo_cont'>
                    {picurl ? <img width={40} src={picurl} alt="" /> : <img width={40} src={person} alt="" />}
                    {/* <img src={logo} width={40} alt="" /> */}
                    <div>
                      <p style={{ margin: "0px", fontSize: '16px' }}>We are Gamers Adda. At Gamers Adda, we connect Gamers and Sponsors. Our goal is...</p>
                    </div>
                  </div>
                  <div className='card_ready_spon'>
                    <p style={{ margin: "0px", fontSize: '12px' }}>Ready to Sponsor</p>
                    <p style={{ margin: "0px" }}>200,000</p>
                  </div>
                </div>
                {/* <div className='hover_card_effect' >
                  {picurl ? <img width={100} src={picurl} alt="" /> : <img width={100} height={90} src={person} alt="" />}
                 
                  <div>We are Gamers Adda. At Gamers Adda, we connect Gamers and Sponsors. Our goal is to make it easier for gamers and Gaming Enthusiasts to organize gaming events, tournaments and more. We are looking for potential Gamers who can promote our brand through Gaming-related events.</div>
                  <p style={{ margin: "0px", marginTop: '10px' }}>Ready to Sponsor</p>
                  <p style={{ margin: "0px" }}>200,000</p>
                </div> */}
              </div>
            </div>
            <div className='ep_submit_pic' onClick={submitBanner} style={{marginTop:'50px'}}>
              <img src={submitbtn} alt="" />
              <p style={{ position: 'absolute', fontSize: '20px' }} >Submit</p>
            </div>
          </div>
        </div>
      </div>


    </>

  )
}
