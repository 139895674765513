import React from 'react'
import bioback from "../../assets/sponsorbioback.png"
import Footer from '../../components/Footer'
import gamers from '../../assets/gamers.png'
import "../../components/css/Profile.css"
import { useState, useEffect } from "react";
import Header from '../Header'
import { useParams } from 'react-router-dom';
import person from '../../assets/person.jpg'
import { useNavigate, Link } from 'react-router-dom';

export default function GamerBio() {


    const [count, setcount] = useState(1);
    let { gamerid } = useParams();
    const [user, setUser] = useState();
    const [bio, setBio] = useState();
    const [event, setEvent] = useState([]);
    const [achievement, setAchievement] = useState([]);
    const host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const [loader, setLoader] = useState(false);
    const [picurl, setPicurl] = useState();

    const fetchBio = async () => {
        const response = await fetch(`${host}/userbio/getbio`, {
            method: 'GET',
            headers: {
                'userid': gamerid
            }
        });
        const json = await response.json();
        if (json.length === 1) {
            setBio(json[0].userBio);
        }

        setLoader(true)
    }

    const fetchImage = async () => {

        const response = await fetch(`${host}/pic/getpic`, {
            method: 'GET',
            headers: {
                'userid': gamerid
            }
        });
        const json = await response.json();
        console.log(json);
        if (json.length === 1) {
            // setCredential({ userbio:  });
            console.log(json[0].picurl);
            setPicurl(json[0].picurl)
        }
        // setLoader(true);

    }
    const fetchUser = async () => {
        const uresponse = await fetch(`${host}/auth/findid`, {
          method: 'GET',
          headers: {
            'userid': gamerid
          }
        });
        const json = await uresponse.json();
        if (json) {
          setUser(json)
        }
      }

    useEffect(() => {

        fetchUser();
        fetchImage();
        fetchBio();

    }, [])



    return (
        <>

            <Header />
            <section>
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>


                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                    {picurl ? <img width={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img width={200} style={{ borderRadius: '50%' }} src={person} alt="" />}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                        {user ? user.username : '-'}
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>

                        <div className={count === 1 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                            Bio
                        </div>
                        <Link to={`/gamerprofile/achievement/${gamerid}`} style={{textDecoration:'none'}}>
                        <div className={count === 2 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                            Achievements
                        </div>
                        </Link>
                        <Link to={`/gamerprofile/event/${gamerid}`} style={{textDecoration:'none'}}>
                        <div className={count === 3 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                            Events
                        </div>
                        </Link>
                        <Link to={`/gamerprofile/message/${gamerid}`} style={{textDecoration:'none'}}>
                        <div className={count === 4 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                            Message
                        </div>
                        </Link>
                    </div>
                </div>

                <div>
                    {loader ?
                        <div className='sp_bio'>
                            <img src={bioback} width={800} alt="" />
                            <p className='sp_bio_contant'>
                                {bio ? bio : "No Bio"}
                            </p>
                        </div> : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                            <div className='spinner_data'> </div>
                        </div>}
                </div>




                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>

                <Footer />

            </section>
        </>
    )
}
