import React, { useState, useEffect } from 'react'
import person from "../../assets/person_logo_gamer.png"
import btnshow from "../../assets/btnedit.png"
import picback from "../../assets/sp_expection_back.png"
import okbtn from "../../assets/sp_budget_ok.png"
import logo from "../../assets/web logo Resize.png"
import submitbtn from "../../assets/submitbtn.png"
import sposnsorcard from '../../assets/background_forcard_demo.png'
import savesponsor from '../../assets/savesponsor.png'
import '../css/EditProfile.css'
import Header from '../Header'
import Footer from '../Footer'
import { useNavigate, Link } from 'react-router-dom';
import Nav_sponsor from './Nav_sponsor'



export default function EditProfile() {
  const [image, setImage] = useState(null);
  // const host = "http://localhost:5000";
  const host = process.env.REACT_APP_API_REQUEST;
  const [user, setUser] = useState();
  const [picurl, setPicurl] = useState();
  const navigate = useNavigate();

  const showupload = () => {
    document.getElementById('pop_updateload').classList.toggle("disnone");
  }

  const showUploadPic = () => {
    document.getElementById('pop_updateload').classList.toggle("disnone");
    document.getElementById('pop_update_pic').classList.toggle("disnone");
  }

  const closeUploadpic = () => {
    document.getElementById('pop_update_pic').classList.toggle("disnone");
  }
  const closeSubmitpic = () => {
    document.getElementById('pop_submit_pic').classList.toggle("disnone");
  }



  const showSubmitPop = () => {
    document.getElementById('userpic').click();
    document.getElementById('pop_update_pic').classList.toggle("disnone");
    document.getElementById('pop_submit_pic').classList.toggle("disnone");
  }

  const showSubmitBanner = () => {
    document.getElementById('userbanner').click();
    document.getElementById('pop_updateload').classList.toggle("disnone");
    document.getElementById('pop_submit_banner').classList.toggle("disnone");
  }
  const closeSubmitBanner = () => {
    document.getElementById('pop_submit_banner').classList.toggle("disnone");
  }



  const setPicWidth = async () => {
    document.getElementById('pop_submit_pic').classList.toggle("disnone");
    console.log(image);


    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "gamer_adda");
    data.append("cloud_name", "dw5zqoz0n");

    try {
      if (image === null) {
        return console.log('work..');
      }

      const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
        method: "POST",
        body: data
      })

      const cloudData = await res.json();
      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();

      const response = await fetch(`${host}/pic/createpic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userPic: cloudData.url, userId: userid._id, picWidth: "100" })
      });
      const json = await response.json();
      navigate('/editsponsor/bio');

    } catch (error) {
      console.log(error);
    }
  }




  const setbackgroundImage = (e) => {
    setImage(e.target.files[0])
    var imageset = document.getElementById('card_background');
    imageset.src = URL.createObjectURL(e.target.files[0]);
  }

  const userImage = (e) => {
    setImage(e.target.files[0])
    var imageset = document.getElementById('userPic');
    imageset.src = URL.createObjectURL(e.target.files[0]);
  }




  const submitBanner = async () => {
    document.getElementById('pop_submit_banner').classList.toggle("disnone");
    console.log(image);

    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "gamer_adda");
    data.append("cloud_name", "dw5zqoz0n");

    try {
      if (image === null) {
        return console.log('work..');
      }

      const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
        method: "POST",
        body: data
      })

      const cloudData = await res.json();
      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();

      const response = await fetch(`${host}/banner/createbanner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userbanner: cloudData.url, userId: userid._id, usertype: userid.whois })
      });
      const json = await response.json();
      navigate('/editsponsor/bio');

    } catch (error) {
      console.log(error);
    }

  }


  const fetchImage = async () => {
    const uresponse = await fetch(`${host}/auth/getuser`, {
      method: 'GET',
      headers: {
        'auth-token': localStorage.getItem('token')
      }
    });
    const userid = await uresponse.json();
    setUser(userid);
    if (userid) {
      const response = await fetch(`${host}/pic/getpic`, {
        method: 'GET',
        headers: {
          'userid': userid._id
        }
      });
      const json = await response.json();
      console.log(json);
      if (json.length === 1) {
        // setCredential({ userbio:  });
        console.log(json[0].picurl);
        setPicurl(json[0].picurl)
      }
      // setLoader(true);
    }
  }


  useEffect(() => {
    fetchImage();
  }, [])



  return (
    <>
      <Header />





      <div>
        <br />
        <br />
        <br />
        <br />
      </div>

      <div>
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                        <Nav_sponsor/>
                    </div>
                    {/* <div>
                        {picurl?  <img width={200} style={{borderRadius:'50%'}} src={picurl} alt="" />: <img width={200} src={person} alt="" />}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                        {user? user.username  : "-" }
                    </div> */}

                    
                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>


                        <Link to="/editsponsor/bio" style={{ textDecoration: 'none' }}>
                        <div className=" profile_header"  >
                            Bio
                        </div>
                        </Link>

                        <Link to="/editsponsor/budget" style={{ textDecoration: 'none' }}>
                            <div className="  profile_header" >
                                Budget
                            </div>
                        </Link>

                        <Link to="/editsponsor/expect" style={{ textDecoration: 'none' }}>
                            <div className="  profile_header" >
                                Expectations
                            </div>
                        </Link>

                            <div className=" profile_nav_white profile_header" >
                                Edit DETAILS
                            </div>

                    </div>
                </div>

            </div>







      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer />


    </>

  )
}
