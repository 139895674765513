import React from 'react';
import logo from '../assets/web logo Resize.png'
import './css/Header.css'
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import login from '../assets/Svg login2.psd.svg'
import person from '../assets/person.jpg'
import bottom_link from '../assets/loginpage_btn.png'
import btn_logsin from '../assets/bell 1 (2).png'
import bell from '../assets/bell 1 (1).png'
import login_back from '../assets/login_btn_back.png'

export default function Header(props) {

    const [user, setUser] = useState()
    const navigate = useNavigate();
    let location = useLocation();
    const host = process.env.REACT_APP_API_REQUEST;
    const [userPic, setUserPic] = useState();

    let mypath = location.pathname
    const moveProgress = () => {
        props.setHeadProgress(10);
        props.setHeadProgress(100);
    }

    const burger = () => {
        document.getElementById('header_link_cont').classList.toggle("displaynone");
        document.getElementById('header_btn').classList.toggle('displaynone')
        document.getElementById('burger').firstChild.classList.toggle('burgerfirstchild')
        document.getElementById('b_second').classList.toggle('burgersecondchild')
        document.getElementById('b_four').classList.toggle('burgerfourchild')
        document.getElementById('burger').lastChild.classList.toggle('burgerthirdchild')
    }

    window.addEventListener("scroll", () => {
        var setnav = document.getElementById('setnav');
        if (setnav) {

            if (window.pageYOffset > 100) {
                document.getElementById('setnav').classList.add('header_scroll')
                document.getElementById('setnav').classList.remove('header_section')
            } else {
                document.getElementById('setnav').classList.remove('header_scroll')
                document.getElementById('setnav').classList.add('header_section')
            }
        }
    })

    const loginpage = () => {
        // document.getElementById('loginsvg').classList.toggle('loginsvgwid');
        document.getElementById('redline').classList.toggle('dis_width');

        setTimeout(() => {
            document.getElementById('crossid').classList.toggle('dis_none');
            document.getElementById('loginpage').classList.toggle('loginpagewidthfull');
            document.getElementById('login_form').classList.toggle('dis_none');
        }, 500);
    }
    const showAccDe = () => {
        document.getElementById('acc_header_drop').classList.toggle('dis_none');
    }
    const LogOut = () => {
        localStorage.clear();
        navigate('/');
        props.myMessage('Farewell for now! Until our next gaming adventure.')
    }

    const userData = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        if (userid) {
            setUser(userid);

            const response = await fetch(`${host}/pic/getpic`, {
                method: 'GET',
                headers: {
                    'userid': userid._id
                }
            });
            const json2 = await response.json();
            setUserPic(json2[0].picurl)

        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {

            userData();
        }
    }, [])

    return (
        <>
            <section id='setnav' className='header_section'>
                <div className='header_sec'>
                    <div className='header_icon' >
                        <Link to="/" className='header_logo_set' style={{display:'flex',textDecoration:'none',alignItems:'flex-end'}}>
                            <img width={60} src={logo} alt="" />
                            <b >Alpha</b>
                        </Link>
                    </div>
                    <div id='header_link_cont' className='header_link_cont displaynone'>
                        <div className={`${location.pathname === '/' ? 'head_link_active' : 'head_link'}`}>
                            <Link onClick={moveProgress} to="/">Home</Link>
                        </div>


                        {location.pathname === '/' || location.pathname === '/esport' || location.pathname === '/about' || location.pathname === '/more' ?
                            <div className="head_link"  >
                                <Link onClick={moveProgress} to="/sponsors">Explore</Link>
                            </div> :
                            <div className={`${location.pathname === '/sponsors' ? 'head_link_active' : 'head_link'}`}>
                                <Link onClick={moveProgress} to="/sponsors">Sponsors</Link>
                            </div>
                        }
                        {location.pathname === '/' || location.pathname === '/esport' || location.pathname === '/about' || location.pathname === '/more' ?
                            <div className={`${location.pathname === '/esport' ? 'head_link_active' : 'head_link'}`}>

                                <Link onClick={moveProgress} to="/esport" style={{textTransform:'inherit'}}>eSPORTS</Link>
                            </div> :
                            <div className={`${location.pathname === '/gamers' ? 'head_link_active' : 'head_link'}`}>
                                <Link onClick={moveProgress} to="/gamers">Gamers</Link>
                            </div>
                        }
                        {location.pathname === '/' || location.pathname === '/esport' || location.pathname === '/about' || location.pathname === '/more' ?
                            <div className={`${location.pathname === '/about' ? 'head_link_active' : 'head_link'}`}>
                                <Link onClick={moveProgress} to="/about">About</Link>
                            </div> : ''
                            // <div className={`${location.pathname === '/buy' ? 'head_link_active' : 'head_link'}`}>
                            //     <Link onClick={moveProgress} to="/buy">Buy</Link>
                            // </div>
                        }
                        {location.pathname === '/' || location.pathname === '/esport' || location.pathname === '/about' || location.pathname === '/more' ?
                            <div className={`${location.pathname === '/more' ? 'head_link_active' : 'head_link'}`}>
                                <Link onClick={moveProgress} to="/more">More</Link>
                            </div> : ''
                            //  <div className={`${location.pathname === '/articles' ? 'head_link_active' : 'head_link'}`}>
                            //     <Link onClick={moveProgress} to="/articles">Actices</Link>
                            // </div>
                        }

                    </div>
                    {localStorage.getItem('token') ?
                        <div style={{ display: 'flex', justifyContent: 'center', width: '360px' }}>
                            <div className='bell_icon_number' style={{opacity:'0'}} >
                                <img src={bell} width={30} alt="" />
                                <div clas>1</div>
                            </div>
                            <div className='h_ac_sec' onClick={showAccDe} >
                                <div className='create_header_btn'>
                                    <p style={{ color: 'black' }}>{user ? user.username.length > 12 ? user.username.slice(0, 10) + '..': user.username : 'name'}</p>
                                    <img id='loginsvgs' style={{opacity:0}} width={200} className='loginsvg' src={btn_logsin} />
                                    {userPic ?
                                        <img src={userPic} style={{ width: '40px', height:'40px', borderRadius: '50%', marginLeft: "-42px" }} alt="" />
                                        :
                                        <img src={person} style={{ width: '40px', borderRadius: '50%', marginLeft: "-42px" }} alt="" />
                                    }
                                </div>

                              
                            </div>
                            <div id='acc_header_drop' className='h_ac_dorpdown dis_none '>
                                {user ? user.whois === "gamer" ? <a href="/editgamer/bio" style={{ textDecoration: 'none', color: 'white' }}>   <div>Edit Profile</div></a> :
                                    <a href="/editsponsor/bio" style={{ textDecoration: 'none', color: 'white' }}>   <div>Edit Profile         </div></a> : ''}

                               
                                <div >About</div>
                              
                                <a href="/more"  style={{ textDecoration: 'none', color: 'white' }}>   <div>FAQ</div></a> 
                                <a href="https://drive.google.com/file/d/1bU-1G9KeAD-iHhBg9W9jYarVbnSpXdyO/view" target='_bla' style={{ textDecoration: 'none', color: 'white' }}>   <div>Terms & Conditions</div></a> 
                             
                                <div onClick={LogOut}>LogOut</div>
                            </div>

                        </div> :
                        <div id='header_btn' className='header_btn displaynone'>
                            <div id='crossid' onClick={loginpage} className='header_cross_btn dis_none' >
                                <span class="material-symbols-outlined"> close </span>
                            </div>
                            <div className='create_header_btn' onClick={loginpage}>
                                <p >Create</p>
                                <img id='loginsvgs' width={55} className='loginsvg' src={btn_logsin} />
                            </div>
                            <span id='redline' className='header_loing_line '></span>
                            <div id='sideline' className='newline_side dis_none'></div>
                        </div>}
                    {/* <div>hi...</div> */}


                </div>
                <div id='burger' onClick={burger} className='burger'>
                    <div id='b_first'></div>
                    <div id='b_second'></div>
                    <div id='b_four'></div>
                    <div id="b_third"></div>
                </div>
            </section>
            <div>
                {/* <img src={Crushed} alt="" /> */}
                {/* <img src={bottom_bo} alt="" /> */}
            </div>

            <section id='loginpage' className='loginpage'>
                <div id='login_form' className='dis_none'>
                    <div id='crossid' onClick={loginpage} className='header_cross_btn ' >
                        <span class="material-symbols-outlined"> close </span>
                    </div>
                    <div id='sideline' className='newline_side '></div>
                    <section className="l-section " >
                        <div className="l-form-box">
                            <div className="l-form-value">

                                <div>
                                    <div>
                                        <div style={{ textAlign: 'center', marginBottom: '105px',fontSize:'33px' }}>
                                            Are you a
                                        </div>
                                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <a className='register_redi_gamer' href="/register/gamer" >
                                                <div style={{ display: 'flex', justifyContent: "center" }}>
                                                    <img src={bottom_link} className='ls_redirct'  alt="" />
                                                    <p >Gamer</p>
                                                </div>
                                            </a>
                                            <a className='register_redi_spons' href="/register/sponsor" >
                                                <div style={{ display: 'flex', justifyContent: "center" }}>
                                                    <img src={bottom_link} className='ls_redirct' alt="" />
                                                    <p>Sponsor</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='home_login_btn' >
                                            <a href="/login">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={login_back} style={{marginTop:'40px'}} className='ls_redirct' alt="" />
                                                    <div className='login_with_me'>Sign In</div>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}
