import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import '../components/css/Login.css'
import { useParams } from 'react-router-dom';
import background from '../assets/registerbackground.png'
import inputfield from '../assets/inputfield.png'
import submitbtn from '../assets/submitbtn.png'


export default function Register() {
    let { whois } = useParams();
    let host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [credential, setCredential] = useState({ username: '', email: '', password: '', cpassword: '', bd: '', country: '' });



    const createUser = async () => {
        let checkbox = document.getElementById("tacCheck");
        if (checkbox.checked) {
            console.log('check...');
        } else {
            return document.getElementById('viewalert').innerText = "Please check the box.";
        }
       


        if (credential.username === '') {
            return document.getElementById('viewalert').innerText = "Username can not be blank.";
        }
        if (credential.email === '') {
            return document.getElementById('viewalert').innerText = "Email can not be blank.";
        }



        console.log('work....');
        var ourSubstring = '@';
        if (!credential.email.includes("@")) {
            return document.getElementById('viewalert').innerText = "Please Enter valid email.";
        }else{
            console.log(credential.email);
        }


  
        const isStrongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,18}$/;
        // const isStrongPassword = /^[a-zA-Z0-9!@#$&()`.+,/"-]*$/;
        // const isStrongPassword = /([a-zA-Z0-9])/;
        // console.log(isStrongPassword.test('aMman@23212'))
        console.log(isStrongPassword.test(credential.password))
        

        // if (credential.password.length <= 4) {
        if (!isStrongPassword.test(credential.password)) {
            return document.getElementById('viewalert').innerText = "Password must contain 8 characters with at least one upper case character, one lower case character, one number, and one special character";
        }

        if (!isStrongPassword.test(credential.cpassword)) {
            return document.getElementById('viewalert').innerText = "Password must contain 8 characters with at least one upper case character, one lower case character, one number, and one special character";
        }


        if (credential.cpassword !== credential.password) {
            return document.getElementById('viewalert').innerText = "Password Does Not Match.";
        }
        if (credential.bd === '') {
            return document.getElementById('viewalert').innerText = "Enter Date of Birth.";
        }
        if (credential.country === '') {
            return document.getElementById('viewalert').innerText = "Select the Country.";
        }

        function underAgeValidate(birthday) {
            var optimizedBirthday = birthday.replace(/-/g, "/");
            var myBirthday = new Date(optimizedBirthday);
            var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
            var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

            if (myAge < 18) {
                return false;
            } else {
                return true;
            }

        }

        if (!underAgeValidate(credential.bd)) {
            return document.getElementById('viewalert').innerText = "You must be 18 years or older.";
        }

        console.log("everything is fine.");
        console.log(credential);

        const uresponse = await fetch(`${host}/auth/findname`, {
            method: 'GET',
            headers: {
                'name': credential.username
            }
        });
        const nameExited = await uresponse.json();
        if (!nameExited.success) {
            return document.getElementById('viewalert').innerText = "Username already exists.";
        }
        const eresponse = await fetch(`${host}/auth/findemail`, {
            method: 'GET',
            headers: {
                'email': credential.email
            }
        });
        const emailExited = await eresponse.json();
        if (!emailExited.success) {
            return document.getElementById('viewalert').innerText = "Email already exists.";
        }
        console.log('Working properly');

        setLoader(false);
        const response = await fetch(`${host}/evotp/adduser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: credential.username, email: credential.email, password: credential.password, birthdate: credential.bd, country: credential.country, whois: whois })
        });
        const json = await response.json();

        if (json) {
            setLoader(true);
            localStorage.setItem('email', credential.email)
            navigate('/otp');
        }
    }

    const onChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value })
    }
    const showPass = (id) => {
        if (document.getElementById(id).type === 'text') {
            document.getElementById(id).type = 'password';
        } else {
            document.getElementById(id).type = 'text';
        }
    }

    setTimeout(() => {
        if (document.getElementById('welcome_into')) {
            document.getElementById('welcome_into').style.display = 'none';
            document.getElementById('main_register_cont').style.display = 'block';
        }
    }, 3000);


    return (
        <>
            <section>

                <div id='welcome_into' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <div style={{ textAlign: 'center', color: 'white' }}>
                        {whois === "gamer" ?
                            <div>
                                <h2>Welcome Gamer!!!</h2>
                                <h2>Sign up to join the Best Gaming Community!!!</h2>
                            </div>
                            :
                            <div>
                                <h2>Welcome Sponsor!!!</h2>
                                <h2>Sign up to join the Best Gaming Community!!!</h2>
                            </div>
                        }
                    </div>
                </div>


                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    {loader ?



                        <div id='main_register_cont' style={{ display: 'none' }}>
                            <div className='register_from_back' >
                                <img src={background} className='register_from_back_img' alt="" />
                                <div className='l_from_set'>
                                    <div className='l_input_col'>
                                        <img src={inputfield} className='input_back_img'  alt="" />
                                        <input id="username" value={credential.username} type='text' onChange={onChange} name="username" placeholder='Username' />
                                    </div>

                                    <div className='l_input_col'>
                                        <img src={inputfield} className='input_back_img' alt="" />
                                        <input id="email" value={credential.email} type='email' onChange={onChange} name="email" placeholder='Email' />
                                    </div>

                                    <div className='l_input_col'>
                                        <img src={inputfield} className='input_back_img' alt="" />
                                        <input id="password" value={credential.password} type='password' onChange={onChange} name="password" placeholder='Password' />
                                        <span class="material-symbols-outlined passiconStyle" onClick={()=> showPass('password')}>visibility</span>
                                    </div>

                                    <div className='l_input_col'>
                                        <img src={inputfield} className='input_back_img' alt="" />
                                        <input id="cpassword" value={credential.cpassword}  onChange={onChange} name="cpassword" type="password" placeholder='Confirm password' />
                                        <span class="material-symbols-outlined passiconStyle" onClick={()=> showPass('cpassword')}>visibility</span>
                                    </div>

                                    <div className='l_input_col'>
                                        <img src={inputfield} className='input_back_img' alt="" />
                                        <input style={{ textTransform: 'uppercase' }} type='date' id="bd" value={credential.bd} onChange={onChange} name="bd" placeholder='Date of Birth (DD-MM-YYYY)' />
                                    </div>
                                    {/* https://flagsapi.com/IN/shiny/64.png */}

                                    <div className='l_input_col'>
                                        <img src={inputfield} className='input_back_img' alt="" />
                                        <select id="country" value={credential.country} onChange={onChange} name="country" style={{ position: 'absolute', padding: '5px', border: 'none', fontSize: '18px', textAlign: 'center',width:'100px' }}>
                                            <option value="">Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antartica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo">Congo, the Democratic Republic of the</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                            <option value="Croatia">Croatia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="France Metropolitan">France, Metropolitan</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                            <option value="Holy See">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran">Iran (Islamic Republic of)</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                            <option value="Korea">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon" selected>Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia">Micronesia, Federated States of</option>
                                            <option value="Moldova">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint LUCIA">Saint LUCIA</option>
                                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                            <option value="Span">Spain</option>
                                            <option value="SriLanka">Sri Lanka</option>
                                            <option value="St. Helena">St. Helena</option>
                                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syrian Arab Republic</option>
                                            <option value="Taiwan">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Viet Nam</option>
                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                    <div style={{fontSize:'20px',color:"white"}}>
                                        <input type="checkbox" id='tacCheck' />
                                        <label htmlFor="">Gamers Adda <a href="https://drive.google.com/file/d/1bU-1G9KeAD-iHhBg9W9jYarVbnSpXdyO/view?pli=1" target='_blank'>Terms & Conditions </a> </label>
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        <p id='viewalert' style={{ color: 'red' }}></p>
                                    </div>



                                    <div className='l_submit_set' onClick={createUser}>
                                        <img src={submitbtn} width={150} alt="" />
                                        <p style={{ position: 'absolute' }}>Let's Go</p>
                                    </div>


                                </div>
                            </div>
                        </div>

                        : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                            <div className='spinner_data'> </div>
                        </div>}
                </div>
            </section>





        </>
    )
}
