import React from 'react'
import gamers from '../assets/gamers.png'
import bage from '../assets/bage.png'
import gb1 from '../assets/gamer_back/gb1.png'
import gb2 from '../assets/gamer_back/gb2.png'
import gb3 from '../assets/gamer_back/gb3.png'
import gb4 from '../assets/gamer_back/gb4.png'
import gb5 from '../assets/gamer_back/gb5.png'
import gb6 from '../assets/gamer_back/gb6.png'

import person from '../assets/person_logo_gamer.png'
import save from '../assets/savesponsor.png'
// import '../components/css/Explore.css'
import '../components/css/Gamer.css'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import Header from '../components/Header'
import { useState, useEffect } from "react";

export default function Gamers(props) {

  const [event, setEvent] = useState([])
  const [gamer, setGamer] = useState([])
  const [ach, setAch] = useState([])
  const [loader, setLoader] = useState(false);
  // const host = "http://localhost:5000";
  const host = process.env.REACT_APP_API_REQUEST;

 

  const sponsors = async () => {
    const uresponse = await fetch(`${host}/getuser/gamer`, {
      method: 'GET',
    });
    const userdata = await uresponse.json();
    console.log(userdata[0][0][0]._id);
    console.log(userdata[0][1][0].userBio);
    console.log(userdata[0][2][0].picurl);
    console.log(userdata[0][3][0].bannerurl);

    console.log(userdata[0][4].length);

    setGamer(userdata)
    setLoader(true)

  }





  useEffect(() => {
    sponsors();
    // userData();
  }, [])


  return (
    <>


      <Header setHeadProgress={props.setProgress} />

      <section>


        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>


        <div style={{ display: 'flex', justifyContent: 'center' }}>









          {loader ?
            <div style={{ width: '1400px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>


              {gamer.map((element, index) => {
                if (element[4].length !== 0) {
                  return <Link style={{ textDecoration: 'none' }} key={index} to={`/gamerprofile/bio/${element[0][0]._id}`}>



                    <div style={{ width: '550px', cursor: 'pointer', margin: '20px' }}>
                      <div className='gamer_card_save'>
                        <img src={save} alt="" />
                      </div>
                      <div>
                        {element[3][0].bannerurl === '1'? <img width={550} src={gb1} alt="" />:''}
                        {element[3][0].bannerurl === '2'? <img width={550} src={gb2} alt="" />:''}
                        {element[3][0].bannerurl === '3'? <img width={550} src={gb3} alt="" />:''}
                        {element[3][0].bannerurl === '4'? <img width={550} src={gb4} alt="" />:''}
                        {element[3][0].bannerurl === '5'? <img width={550} src={gb5} alt="" />:''}
                        {element[3][0].bannerurl === '6'? <img width={550} src={gb6} alt="" />:''}
        
                        {/* <img width={550} src={gamer_back} alt="" /> */}
                      </div>
                      <div className='gamer_card_contant'>

                        {/* <img width={177} src={person} alt="" /> */}
                        {element[2][0].picurl ? <img style={{borderRadius:'50%'}} src={element[2][0].picurl} width={177} /> : <img src={person} width={177} alt="" />}
                        <div className='gc_contant'>
                          <b style={{ fontSize: '20px' }}>{element[0][0].username}</b>
                          <p>Hi, I am Muthu, and I have been an avid gamer for 10+ years now.I love FPS games and have hosted a few LAN partiesamong my friends. I love Valorant, CSGO and more.I also livestream on Twitch and YouTube and runa Discord server with 1000+ members.</p>
                          <p className='gamer_card_upevent'>1 Upcoming Event</p>
                        </div>
                      </div>
                    </div>



                  </Link>
                }

              })}


            </div>
            : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
              <div className='spinner_data'></div>
            </div>}








        </div>












        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

      </section>

      <Footer />


    </>
  )
}
