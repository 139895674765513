import React from 'react'
import { useState, useEffect } from "react";
import person from "../../assets/person_logo_gamer.png"
import reactangle from "../../assets/reactange.png"
import btnshow from "../../assets/btnedit.png"
import moreoption from '../../assets/moreoption.png'
import logo from "../../assets/web logo Resize.png"
import submitbtn from "../../assets/submitbtn.png"
import gamer_back from "../../assets/gamer_back/gb1.png"
import gamer_back1 from "../../assets/gamer_back/gb2.png"
import gamer_back2 from "../../assets/gamer_back/gb3.png"
import gamer_back3 from "../../assets/gamer_back/gb4.png"
import gamer_back4 from "../../assets/gamer_back/gb5.png"
import gamer_back5 from "../../assets/gamer_back/gb6.png"
import Header from '../Header'
import Footer from '../Footer'
import '../css/EditProfile.css'
import { useNavigate, Link } from 'react-router-dom';

export default function Nav_gamer() {

    const [image, setImage] = useState(null);
    const [user, setUser] = useState();
    const [bg, setBg] = useState(reactangle);
    const [bgNumber, setBgNumber] = useState();
    const [bgNumberString, setBgNumberString] = useState();
    const [picurl, setPicurl] = useState();
    const host = process.env.REACT_APP_API_REQUEST;
    const navigate = useNavigate();

    const showupload = () => {
        document.getElementById('pop_updateload').classList.toggle("disnone");
    }

    const closeUploadpop = () => {
        document.getElementById('pop_update').classList.toggle("disnone");
    }
    const showUploadPop = () => {
        document.getElementById('pop_updateload').classList.toggle("disnone");
        document.getElementById('pop_update').classList.toggle("disnone");
    }
    const showpicPop = () => {
        document.getElementById('userpic').click();
        document.getElementById('pop_update').classList.toggle("disnone");
        document.getElementById('pop_submit_pic').classList.toggle("disnone");
    }
    const closeSubmiPic = () => {
        document.getElementById('pop_submit_pic').classList.toggle("disnone");
    }
    const setBackground = () => {
        document.getElementById('pop_submit_pic').classList.toggle("disnone");
        document.getElementById('pop_submit_background').classList.toggle("disnone");
    }
    const closeBackground = () => {

        document.getElementById('pop_submit_background').classList.toggle("disnone");
    }

    const fetchImage = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        setUser(userid);
        if (userid) {
            const response = await fetch(`${host}/pic/getpic`, {
                method: 'GET',
                headers: {
                    'userid': userid._id
                }
            });
            const json = await response.json();
            if (json.length === 1) {
                setPicurl(json[0].picurl)
                const responseb = await fetch(`${host}/banner/getbanner`, {
                    method: 'GET',
                    headers: {
                        'userid': userid._id
                    }
                });
                const json21 = await responseb.json();
                console.log(json21[0].bannerurl);

                if (json21[0].bannerurl === '1') {
                    
                    setBgNumberString(gamer_back)
                }else if(json21[0].bannerurl === '2'){
                    setBgNumberString(gamer_back1)
                
                }else if(json21[0].bannerurl === '3'){
                    setBgNumberString(gamer_back2)

                }else if(json21[0].bannerurl === '4'){

                    setBgNumberString(gamer_back3)
                }else if(json21[0].bannerurl === '5'){
                    setBgNumberString(gamer_back4)

                }else if(json21[0].bannerurl === '6'){
                    setBgNumberString(gamer_back5)
                }





            }
        }
    }

    const setGamerImage = (e) => {
        setImage(e.target.files[0])
        var imageset = document.getElementById('gamerPicid');
        imageset.src = URL.createObjectURL(e.target.files[0]);

        var imageset2 = document.getElementById('gamercardPic');
        imageset2.src = URL.createObjectURL(e.target.files[0]);
    }


    useEffect(() => {
        fetchImage();
    }, [])


    const changeBackground = (e) => {
        if (e=== 1) {
            
            setBg(gamer_back)
            setBgNumber(1)
        }else if(e === 2){
            setBg(gamer_back1)
            setBgNumber(2)
        }
        else if(e === 3){
            setBg(gamer_back2)
            setBgNumber(3)
        
        }else if(e === 4){
            setBg(gamer_back3)
            setBgNumber(4)
        
        }else if(e === 5){
            setBg(gamer_back4)
            setBgNumber(5)
        
        }else if(e === 6){
            setBg(gamer_back5)
            setBgNumber(6)
        }
        console.log(bgNumber);
    }

    const submitUserBgPic = async () => {
        document.getElementById('pop_submit_background').classList.toggle("disnone");

        const data = new FormData();

        data.append("file", image);
        data.append("upload_preset", "gamer_adda");
        data.append("cloud_name", "dw5zqoz0n");

        try {
            if (image === null) {
                return console.log('work..');
            }

            const res = await fetch('https://api.cloudinary.com/v1_1/dw5zqoz0n/image/upload', {
                method: "POST",
                body: data
            })

            const cloudData = await res.json();
            const uresponse = await fetch(`${host}/auth/getuser`, {
                method: 'GET',
                headers: {
                    'auth-token': localStorage.getItem('token')
                }
            });
            const userid = await uresponse.json();

            const response = await fetch(`${host}/pic/createpic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userPic: cloudData.url, userId: userid._id, picWidth: "100" })
            });
            const json = await response.json();

            if (json) {

                const responseb = await fetch(`${host}/banner/createbanner`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ userbanner: bgNumber, userId: userid._id, usertype: userid.whois })
                });
                const json2 = await responseb.json();


                window.location.reload();
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
     
            <div>
                <br />
                <br />
        
            </div>




            <div>

                <div className='ep_cont_top'>

                    <div style={{ cursor: 'pointer' }} className='ep_person_cont' onClick={showupload}>
                        {picurl ? <img width={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img width={200} src={person} alt="" />}
                        <div className='ep_edit_person_icon'>
                            <span class="material-symbols-outlined">edit</span>
                            <span>Edit</span>
                        </div>
                    </div>
                    <div style={{ fontSize: '34px' }}>
                        <b> {user ? user.username : "-"}</b>
                    </div>
                </div>


                <div id='pop_updateload' className='disnone'>
                    <div className='ep_banner_pic'>
                        <span class="material-symbols-outlined ep_close" onClick={showupload}>close</span>

                        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', cursor: 'pointer' }} onClick={showUploadPop}>
                            <img src={btnshow} alt="" />
                            <p style={{ position: 'absolute' }}>Upload profile/Banner</p>
                        </div>
                    </div>
                </div>


                <div id='pop_update' className='disnone'>
                    <div className='ep_banner_pic'>
                        <span class="material-symbols-outlined ep_close" onClick={closeUploadpop}>close</span>

                        <div className='ep_pop_pic'>
                            <div style={{ width: '550px', cursor: 'pointer', margin: '20px' }}>
                                {/* <div className='gamer_card_save'>
                                    <img src={save} alt="" />
                                </div> */}
                                <div>
                                    {/* <img width={550} src={gamer_back} alt="" /> */}
                                </div>
                                {/* bgNumberString */}
                                {/* {bgNumberString === '1'?<div className='gamer_card_contant' style={{ backgroundImage: `url(${gamer_back})`, backgroundRepeat:'no-repeat',backgroundSize:'cover', color: 'black', marginTop: '100px' }}>:''} */}
                        
                               

                                
                                
                                <div className='gamer_card_contant' style={{  backgroundImage: `url(${bgNumberString})`, backgroundRepeat:'no-repeat',backgroundSize:'cover', color: 'black', marginTop: '100px' }}>
                                    <div style={{ cursor: 'pointer', marginTop: '-155px' }} className='ep_person_cont' onClick={showpicPop}>
                                    {picurl ? <img width={177} src={picurl} alt="" style={{borderRadius:'50%'}} /> : <img width={177} src={person} alt="" />}
                                        {/* <img width={177} src={person} alt="" /> */}
                                        <div className='ep_edit_person_icon'>
                                            <span class="material-symbols-outlined">edit</span>
                                            <span>Edit</span>
                                        </div>
                                    </div>
                                    {/* <img width={177} src={person} alt="" /> */}
                                    <div className='gc_contant'  style={{color:'white'}}>
                                        <b style={{ fontSize: '20px' }}>Muthu</b>
                                        <p>Hi, I am Muthu, and I have been an avid gamer for 10+ years now.I love FPS games and have hosted a few LAN partiesamong my friends. I love Valorant, CSGO and more.I also livestream on Twitch and YouTube and runa Discord server with 1000+ members.</p>
                                        <p className='gamer_card_upevent'>1 Upcoming Event</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='pop_submit_pic' className='disnone'>
                    <div className='ep_banner_pic'>
                        <span class="material-symbols-outlined ep_close" onClick={closeSubmiPic}>close</span>
                        <div style={{ position: "fixed", top: "0", left: '1px' }}>
                            <input type="file" id='userpic' onChange={setGamerImage} />
                        </div>
                        <div>
                            <div className='ep_pic_set_back'>
                                {/* {picurl ? <img width={100} style={{ borderRadius: '50%' }} src={picurl} id='gamerPicid' alt="" /> : <img width={100} src={person} alt="" id='gamerPicid' />} */}
                                <img src={person} width={100} style={{ borderRadius: '50%' }} alt="" id='gamerPicid' />
                            </div>
                            <div>
                                <input type="range" />
                            </div>
                            <div className='ep_submit_pic' onClick={setBackground}>
                                <img src={submitbtn} alt="" />
                                <p style={{ position: 'absolute', fontSize: '20px' }}>Ok</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='pop_submit_background' className='disnone'>
                    <div className='ep_banner_pic'>
                        <span class="material-symbols-outlined ep_close" onClick={closeBackground}>close</span>
                        <div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '550px', margin: '20px', backgroundImage: `url(${bg})`, backgroundRepeat:'no-repeat',backgroundSize:'cover' }}>
                                    <div className='gamer_card_contant' style={{ color: 'white', marginTop: '0px' }}>

                                        {/* {picurl ? <img width={150} height={150} style={{ borderRadius: '50%', marginTop: '10px' }} src={picurl} id='gamercardPic' alt="" /> : <img width={150} height={150} style={{ borderRadius: '50%', marginTop: '10px' }} src={person} id='gamercardPic' alt="" />} */}
                                        <img width={150} height={150} style={{ borderRadius: '50%', marginTop: '10px' }} src={person} id='gamercardPic' alt="" />

                                        <div className='gc_contant'>
                                            <b style={{ fontSize: '20px' }}>Muthu</b>
                                            <p>Hi, I am Muthu, and I have been an avid gamer for 10+ years now.I love FPS games and have hosted a few LAN partiesamong my friends. I love Valorant, CSGO and more.I also livestream on Twitch and YouTube and runa Discord server with 1000+ members.</p>
                                            <p className='gamer_card_upevent'>1 Upcoming Event</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={moreoption} width={300} alt="" />

                                    <div className='background_gamer_options' style={{ position: 'absolute', padding: '47px 57px' }}>

                                        <img src={gamer_back} width={100} onClick={() => changeBackground(1)} className='c_b_c_g' style={{ cursor: 'pointer' }} alt="" />
                                        <img src={gamer_back1} width={100} onClick={() => changeBackground(2)} className='c_b_c_g' style={{ cursor: 'pointer' }} alt="" />
                                        <img src={gamer_back2} width={100} onClick={() => changeBackground(3)} className='c_b_c_g' style={{ cursor: 'pointer' }} alt="" />
                                        <img src={gamer_back3} width={100} onClick={() => changeBackground(4)} className='c_b_c_g' style={{ cursor: 'pointer' }} alt="" />
                                        <img src={gamer_back4} width={100} onClick={() => changeBackground(5)} className='c_b_c_g' style={{ cursor: 'pointer' }} alt="" />
                                        <img src={gamer_back5} width={100} onClick={() => changeBackground(6)} className='c_b_c_g' style={{ cursor: 'pointer' }} alt="" />

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='ep_submit_pic' onClick={submitUserBgPic}>
                                    <img src={submitbtn} alt="" />
                                    <p style={{ position: 'absolute', fontSize: '20px' }}>Apply</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



























            </div>

  
        </>
    )
}
