import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../css/InnerMessage.css'

import person from '../../assets/person.jpg'
import { useState, useEffect } from "react";
import typeLoad from "../../assets/coming soon .png"
import ScrollableFeed from 'react-scrollable-feed'

import { useParams } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';

// import { io } from 'socket.io-client';

// const socket = io.connect('http://localhost:5000');



// const socket = io.connect(process.env.REACT_APP_API_REQUEST);
// const socket = io("wss://gamer-adda-backend.vercel.app");

export default function SponsorMassage() {

    const [user, setUser] = useState();
    const [chatMessage, setChatMessage] = useState([]);
    const [credential, setCredential] = useState({ userbio: '' });
    const [message, setMessage] = useState();
    const [socketConnect, setSocketConnect] = useState(false);
    let { sponsorid } = useParams();
    const host = process.env.REACT_APP_API_REQUEST;
    // const host = "http://localhost:5000";
    const [loader, setLoader] = useState(true);
    const [count, setcount] = useState(4);
    const [picurl, setPicurl] = useState();
    const [mychat, setMychat] = useState(false);


    const [chatIdRoom, setChatIdRoom] = useState();
    const [typing, setTyping] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const fetchUser = async () => {
        const uresponse = await fetch(`${host}/auth/findid`, {
            method: 'GET',
            headers: {
                'userid': sponsorid
            }
        });
        const json = await uresponse.json();
        if (json) {
            setUser(json)
        }
    }



    const fetchImage = async () => {

        const response = await fetch(`${host}/pic/getpic`, {
            method: 'GET',
            headers: {
                'userid': sponsorid
            }
        });
        const json = await response.json();

        if (json.length === 1) {
            setPicurl(json[0].picurl)
        }
    }



    const sumbitMessage = async (e) => {
        e.preventDefault();
        console.log(message);
        // socket.emit('stop typing', chatIdRoom)
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();

        const response = await fetch(`${host}/chat/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ myId: userid._id, friendId: sponsorid, latestMessage: message })
        });
        const json = await response.json();
        if (json) {
            const response2 = await fetch(`${host}/message/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sender: userid._id, chatId: json._id, content: message })
            });
            const json2 = await response2.json();
            if (json2) {
                setMessage('');
                document.getElementById('innermesvalue').value = '';
                setChatMessage(chatMessage => [...chatMessage, json2])
                // socket.emit("new message", json2)
                console.log(json2);
            }
        }
    }







    // useEffect(() => {
    //     const handleMessage = (data) => {
    //         console.log(data);
    //         document.getElementById('chatinner').innerHTML += `  <div style="display:flex;">${data.message}</div>`
    //     };

    //     socket.on("receive_massage", handleMessage);
    //     return () => {
    //         socket.off("receive_massage", handleMessage);
    //     };
    // }, [socket]);


    const fetchChat = async () => {
        const uresponse = await fetch(`${host}/auth/getuser`, {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        });
        const userid = await uresponse.json();
        const response = await fetch(`${host}/chat/getchatid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ myId: userid._id, friendId: sponsorid })
        });
        const json = await response.json();
        if (json) {
            if (json.length === 0) {
                setMychat(true)

            } else {


                setChatIdRoom(json[0]._id)
                console.log(json);
                console.log(userid._id);
                console.log(sponsorid);
                const uresponse = await fetch(`${host}/message/view`, {
                    method: 'GET',
                    headers: {
                        'chatid': json[0]._id
                    }
                });
                const message = await uresponse.json();
                setChatMessage(message);
                // socket.emit('join chat', json[0]._id);
            }
        }
    }


    // useEffect(() => {

    //     socket.on('message recieved',(newMessageRecieved)=>{
    //         setChatMessage([...chatMessage, newMessageRecieved])
    //     })
    // })




    // useEffect(() => {
    //     const handleMessage = (data) => {
    //         console.log(chatMessage);
    //         setChatMessage(chatMessage => [...chatMessage, data])
    //         // setChatMessage([...chatMessage, data])
    //         console.log("recieved data");
    //         // document.getElementById('chatinner').innerHTML += `  <div style="display:flex;">${data.message}</div>`
    //     };

    //     socket.on("message recieved", handleMessage);
    //     return () => {
    //         socket.off("message recieved", handleMessage);
    //     };
    // }, [socket]);







    // useEffect(() => {
    //     //   socket = io(socket);
    //     socket.emit("setup", sponsorid);
    //     socket.on("connected", () => setSocketConnect(true))
    //     socket.on('typing', () => setIsTyping(true))
    //     socket.on('stop typing', () => setIsTyping(false))

    // }, []);


    useEffect(() => {
        fetchImage();
        fetchUser();
        fetchChat();

    }, [])

    // const typingHanlder = (e) => {
    //     setMessage(e.target.value)
    //     console.log(socketConnect);

    //     if (!socketConnect) return;

    //     if (!typing) {

    //         setTyping(true)
    //         socket.emit('typing', chatIdRoom)
    //     }

    //     let lastTypingTime = new Date().getTime()
    //     var timerLenght = 3000;

    //     setTimeout(() => {
    //         var timeNow = new Date().getTime();
    //         var timeDiff = timeNow - lastTypingTime
    //         if (timeDiff >= timerLenght && typing) {
    //             socket.emit("stop typing", chatIdRoom);
    //             setTyping(false);
    //         }
    //     }, timerLenght);

    // }


    return (

        <>
            <Header />


            <section>
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>


                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div>
                        {picurl ? <img width={200} height={200} style={{ borderRadius: '50%' }} src={picurl} alt="" /> : <img width={200} style={{ borderRadius: '50%' }} src={person} alt="" />}
                        {/* <img width={200} src={sponsors} alt="" /> */}
                    </div>
                    <div style={{ color: 'white', fontSize: '31px', fontWeight: '900' }}>
                        {user ? user.username : '-'}
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <img style={{ borderRadius: '6px' }} width={100} src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" alt="" />
                    </div>
                </div>

                <div className='profile_section'>
                    <div className='profile_sec'>
                        <Link to={`/sponsorprofile/bio/${sponsorid}`} style={{ textDecoration: "none" }}>
                            <div className={count === 1 ? 'profile_nav_white profile_header' : 'profile_header'}   >
                                Bio
                            </div>
                        </Link>
                        <Link to={`/sponsorprofile/budget/${sponsorid}`} style={{ textDecoration: "none" }}>
                            <div className={count === 2 ? 'profile_nav_white profile_header' : 'profile_header'}>
                                Budget
                            </div>
                        </Link>

                        <Link to={`/sponsorprofile/expectation/${sponsorid}`} style={{ textDecoration: "none" }}>
                            <div className={count === 3 ? 'profile_nav_white profile_header' : 'profile_header'}>
                                Expectations
                            </div>
                        </Link>
                        <div className={count === 4 ? 'profile_nav_white profile_header' : 'profile_header'}>
                            Message
                        </div>
                    </div>
                </div>

                {/* 
                {loader ?
                    <div>
                        {mychat? <div style={{color:'white',textAlign:'center'}}> this is your chat you cont enter any thing...</div> :  
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ color: 'white', textAlign: 'center', width: '1000px', fontSize: '25px' }}>
                               
                                <div id='chatinner' style={{ height: '400px', overflow: 'auto' }}>
                                    <ScrollableFeed>
                                        {chatMessage.map((element, myid) => {
                                            if (element.sender === sponsorid) {
                                                return <div key={myid} style={{ display: "flex" }}>{element.content}</div>
                                            } else {
                                                return <div key={myid} style={{ display: "flex", justifyContent: "flex-end" }}>{element.content}</div>
                                            }
                                        })}
                                    </ScrollableFeed>
                                </div>
                                <div>
                                    {isTyping ? <div style={{ display: 'flex' }}><img src={typeLoad} width={50} style={{ borderRadius: '20px' }} alt="" /></div> : <div style={{ opacity: '0' }}><img src={typeLoad} width={50} style={{ borderRadius: '20px' }} alt="" /></div>}
                                    <form onSubmit={sumbitMessage}>

                                        <input className='sp_in_input' type="text" name="" id="innermesvalue" onChange={typingHanlder} />
                                    </form>
                                </div>
                            </p>

                        </div>}
                    </div> : <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                        <div className='spinner_data'> </div>
                    </div>
                } */}

                <div className='coming_soon_style'>
                    <img src={typeLoad}  alt="" />
                </div>


                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>


                <Footer />

            </section>

        </>
    )
}
