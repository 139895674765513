import React from 'react'
import './css/Footer.css'
import logo from '../assets/web logo Resize.png'
import social1 from '../assets/social_media/discord.png'
import social2 from '../assets/social_media/facebook.png'
import social3 from '../assets/social_media/instagram.png'
import social4 from '../assets/social_media/linkdein.png'
import social5 from '../assets/social_media/twitter.png'
import social6 from '../assets/social_media/youtube.png'


export default function Footer() {
    return (
        <>

            <section>

                <div className='set_upper_footer'>
                    <footer className='set_footer'>
                        <div className='footer_col_1'>
                            <div className='f_icon_sec'>
                                <img width={70} src={logo} alt="" />
                                <div>
                                    <b>Gamers Adda</b>
                                </div>
                            </div>
                            <div style={{ paddingRight: '10px' }}>
                                <p>Bringing Gamers together!</p>
                            </div>
                            <div>
                                <div>
                                    Connect with Us -- 	&gt; 	&gt;
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <a href="https://www.linkedin.com/in/gamers-adda-7099882a8/" target='_blank'>
                                    <img className="footer-icon" src={social4} alt="" /></a>
                                    <a href="" target='_blank'>
                                    <img className="footer-icon" src={social2} alt="" /></a>
                                    <a href="https://www.instagram.com/_gamersadda_/" target='_blank'>
                                    <img className="footer-icon" src={social3} alt="" /></a>
                                    <a href="https://www.youtube.com/@GamersAdda" target='_blank'>
                                    <img className="footer-icon" src={social6} alt="" /></a>
                                    <a href="https://twitter.com/_GamersAdda_" target='_blank'>
                                    <img className="footer-icon" src={social5} alt="" /></a>
                                    <a href="https://discord.gg/s64tgc5e" target='_blank'>
                                    <img className="footer-icon" src={social1} alt="" /></a>
                                   
                                </div>
                            </div>

                        </div>
                        <div className='footer_col_2'>
                            <div className='footer_link_head'>
                                <b style={{textTransform:'uppercase'}}>Quick Link</b>
                            </div>
                            <div className='footer_links'>
                                <a href="">Home</a>
                                <a href="">Explore</a>
                                <a href="">eSports</a>
                         
                            </div>
                        </div>
                        <div className='footer_col_3'>
                            <div className='footer_link_head'>
                                <b>SUPPORT</b>
                            </div>
                            <div className='footer_links'>
                                <a href="">About</a>
                                <a href="/more">FAQ</a>
                                <a href="https://drive.google.com/file/d/1bU-1G9KeAD-iHhBg9W9jYarVbnSpXdyO/view" target='_blaknk'>T&C</a>

                            </div>
                        </div>
                        {/* <div className='footer_col_4'>
                            <div className='footer_link_head'>
                                <b>NEWSLETTER</b>
                            </div>
                            <div>
                                <p>Subscribe our newsletter to get our latest update & newsconsectetur</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <input className="newletterst" placeholder="Enter email address" type="text" />
                                <img className="newletterbtn" src="https://raw.githubusercontent.com/imamangupta/Play-Ground/main/Img/sendemail.webp" alt="" />
                            </div>
                        </div> */}
                    </footer>
                </div>
                <div className='set_upper_copy_right'>

                <div className='footer_copy_right'>
                    COPYRIGHT © 2024 - ALL RIGHTS RESERVED BY GAMERS ADDA
                </div>
                </div>

            </section>
        </>
    )
}
